<template>
<!-- :render-label="renderLabel" -->
    <n-select filterable v-model:value="id" :options="subjectListOldList" @update:value="fn" size="large" :consistent-menu-width="false">
      <template #action>
        <span @click="addCode" style="color:red;cursor: pointer;">新增科目</span>
        <add-subject @success="addSuccess" ref="addSubject"></add-subject>
      </template>
    </n-select>
    <!-- <add-subject @success="addSuccess" ref="addSubject"></add-subject> -->
</template>
<script>


export default {
  props: {
    codes: {//默认下拉框的code
      type: Array,
      default: []
    },
    subjectId: {
      type: Number,
      default: 0
    },
    fzhsItemId: {
      type: Number,
      default: 0
    },
    subjectName: {//新增科目的名字
      type: String,
      default: "",
    },
    signName: {
      type: String,
      default: "",
    },
  },
  computed: {
    subjectListOldList(){
      let list = this.$subjectFilterCode(this.codes, 1)
      list.map(v=>{
        v.value = v.sign
        if(v.fzhsItemName){
          v.label = v.name3 + " --辅助核算：" + v.fzhsItemName
        }else{
          v.label = v.name3
        }
      })
      return list
    },
  },
  data () {
    return {
      // renderLabel(option) {
      //   option.label = option.name3
      //   option.value = option.sign
      //   return option.name3
      // },
      id: this.subjectId?this.subjectId + "-" + this.fzhsItemId:""
    }
  },
  methods: {
    fn(a,b){
      this.changeCode()
      // console.log(a,b)
      this.$emit('update-enter')
    },
    addCode(){
      let data = {
        id: undefined,
        name: this.subjectName,
        type: 0,
      }
      this.$refs.addSubject.init(data, this.codes)
    },
    addSuccess(e){
      this.id = e.sign
      this.changeCode()
    },
    changeCode(){
      let infos = this.subjectListOldList.filter(v=>{return this.id == v.sign})
      if(infos.length != 0){
        if(infos[0].fzhsItemName){
          this.$emit("update:signName", infos[0].name3 + " --辅助核算：" + infos[0].fzhsItemName)
        }else{
          this.$emit("update:signName", infos[0].name3)
        }
      }
      this.$emit("update:subjectId", this.id.split('-')[0] * 1)
      this.$emit("update:fzhsItemId", this.id.split('-')[1] * 1)
      this.$emit("success", this.id)
    }
  }
}
</script>
<style scoped>

</style>