<template>
<div class="tabs">
  <div class="tabs-bar">
  <!-- 标签页标题，通过v-for实现循环 -->
    <span :class="tabCls(item)" v-for="(item,index) in navList" @click="handleChange(index)" :key="item.name">
    <span>{{item.label}}</span>
    <div class="xian"></div>
    <div class="sanjiaoxing"></div>
    <!-- <span class="sanjiaoxingSpan"></span> -->
    </span>
  </div>
  <div class="tabs-content">
  <!-- slot放置pane组件内容 -->
    <slot></slot>
  </div>
</div>
</template>

<script>
export default {
  components: {
  },
  props: {
    value: "",
  },
  data () {
    return {
      navList: [],
      currentValue: this.value
    }
  },
  methods: {
    tabCls (item) {
      return [
        'tabs-tab',
        {
          'tabs-tab-active': item.name === this.currentValue,
        }
      ]
    },
    handleChange(index){
      this.currentValue = this.navList[index].name
      this.$emit("update:value", this.navList[index].name)
      this.$emit("tab-click", this.navList[index].name)
    },
    getTabs () {
    //获取pane
      return this.$slots.default().filter(function (item) {
        return item.type.name === 'pane'
      })
    },
    updateNav () {
    //获取标题，name,并放置到navList数组中
      this.navList = []
      let _this = this
      this.getTabs().forEach(function (pane, index) {
        _this.navList.push({
          label: pane.props.label,
          name: pane.props.name || index

        })
        if (index === 0) {
          if (!_this.currentValue) {
            _this.$emit("update:value", pane.props.name)
            _this.$emit("tab-click", pane.props.name)
          }
        }
      })
    },
  },
}

// <qzf-tabs v-model:value="value">
//     <qzf-tabs-item label="标签一" name="1">
//       哈哈哈
//     </qzf-tabs-item>
//     <qzf-tabs-item label="标签二" name="2">
//       呵呵呵
//     </qzf-tabs-item>
//   </qzf-tabs>
</script>

<style lang="scss" scoped>
[v-cloak]{
    display: none;
}
.tabs{
    font-size: 14px;
    color: #657180;
    height: 100%;
    .tabs-bar{
      width: 37px;
      height: 100%;
      float: left;
      overflow-x: hidden;
      overflow-y: scroll;
      margin-top: 1px;
    }
    .tabs-content{
      float: left;
      width: calc(100% - 40px);
      height: 100%;
    }
}
.tabs-bar:after{
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #d7dde4;
    margin-top:-1px;
}
.tabs-tab{
    display: inline-block;
    // padding: 10px 10px;
    padding: 25px 10px 0px 10px;
    background: $color-left-ground;
    cursor: pointer;
    position: relative;
    line-height: 20px;
    width: 18px;
    .xian{
      width: 41px;
      height: 1px;
      background: #efefef;
      position: absolute;
      bottom: -9px;
      left: 0;
      z-index: 1000;
      transform:rotateZ(23deg);
    }
    .sanjiaoxing{
      border-top: 19px solid $color-left-ground;
      border-left: 44px solid transparent;
      position:relative;
      top: 16px;
      z-index: 999;
      left: -16px;
    }
    .sanjiaoxingSpan{
      border-top: 22px solid $color-left-ground;
      border-left: 44px solid transparent;
      position:relative;
      top: 46px;
      z-index: 999;
      left: -31px;
    }
}

// $color-main: red;
.tabs-tab-active{
    color: white;
    background: $color-main;
    width: 18px;
    .sanjiaoxing{
      border-top: 19px solid $color-main;
      border-left: 44px solid transparent;
      position:relative;
      top: 16px;
      z-index: 999;
      left: -16px;
    }
    .sanjiaoxingSpan{
      border-top: 22px solid $color-main;
      border-left: 44px solid transparent;
      position:relative;
      top: 46px;
      z-index: 999;
      left: -31px;
    }
}
// .tabs-tab-active:before{
//     content: '';
//     display: block;
//     height: 1px;
//     background: #3399ff;
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
// }
.tabs-content{
    // padding: 8px 0;
}
// .xxx{
//   border-top: 25px solid $color-main;
//   border-left: 38px solid transparent;
//   position:absolute;
//   top: 64px;
//   z-index: 999;
//   left: -1px;
// }
::-webkit-scrollbar {//滚动条的宽度
width:0px;
height:0px;
}
</style>
