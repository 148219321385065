<template>
  <div v-if="cache">
    <div class="pane" v-show="show">
      <slot></slot>
    </div>
  </div>

  <div v-else>
    <div class="pane" v-if="show">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pane',
  props: {
    name: {
      type: String
    },
    cache:{
      type:Boolean,
      default:true
    },
    label: {
      type: String,
      default: ''
    },
  },
  computed:{
    show(){
      return this.$parent.currentValue == this.name
    }
  },
  data () {
    return {
      // show: true
    }
  },
  methods: {
    updateNav () {
      this.$parent.updateNav()
    }
  },
  watch: {
    label () {
      this.updateNav()
    }
  },
  mounted () {
    this.updateNav()
  },
}
</script>
<style  scoped>
</style>
