<template>
  <el-dialog :close-on-click-modal="false"
    :title="titleType"
    v-model="dialogVisible"
    width="23%"
    destroy-on-close
  >
    <el-form :model="form" :rules="rules" ref="ruleForm" size="small">
      <el-form-item label="名称" :label-width="formLabelWidth" prop="name">
        <el-input v-model="form.name" autocomplete="off" size="small" style="width:180px"></el-input>
      </el-form-item>
      <el-form-item label="绑定科目" :label-width="formLabelWidth" prop="subjectId">
        <subject-list :width="180" :subjectName="form.name" v-model:subjectId="form.subjectId" v-model:fzhsItemId="form.fzhsId" :codes="$findCode(code)"></subject-list>
      </el-form-item>
      <el-form-item label="类型：" style="float:left" :label-width="formLabelWidth">
        <el-radio-group v-model="type">
          <el-radio disabled label="1">客户</el-radio>
          <el-radio disabled label="2">供应商</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <qzf-button size="small" @success="dialogVisible = false">取 消</qzf-button>
        <qzf-button size="small" type="primary" @success="addBusinessSure">确 定</qzf-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { saveCorpCoop } from "@/api/coop"
export default {
  name:'add',
  props:{
    type:String,
  },
  data() {
    return{
      dialogVisible:false,
      form: {},
      titleType:'',
      code: ['code1285','code1403','code1405','code1406','code1408','code1411','code1412','code1421','code1605'],
      formLabelWidth: '80px',
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        subjectId: [
          { required: true, message: '请选择科目', trigger: 'change' },
        ],
      },
    }
  },
  methods:{
    init(row){
      if(this.type == '1'){
        this.code = ['code1001','code1002','code1012','code1121','code2701','code1122','code1221','code2201','code2202','code2203','code2241']
      }else{
        this.code = ['code1001','code1002','code1012','code1121','code2701','code1122','code1221','code1123','code2201','code2202','code2203','code2241']
      }
      // todo
      if(row){
        this.form = row
      }else{
        this.form = {}
      }
      this.dialogVisible = true
    },
    addBusinessSure(){
      this.form.type = this.type
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          saveCorpCoop(this.form).then(res=>{
            // console.log(res);
            if(res.data.msg == "success"){
              this.dialogVisible = false
              this.$store.dispatch("commons/getCorp")
              this.$qzfMessage("设置成功")
              this.$emit("success",res.data.data.info)
            }
          })
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    changeCode(){
    },
    changeSubject(e){
      // console.log(e);
    }
  }

}
</script>


