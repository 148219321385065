import request from '@/utils/request'


export function uploadFile(formdata) {
    return request({
        url: 'api/v1/uploadFile',
        method: 'post',
        data: formdata,
        config: {
            headers: { 'Content-Type': 'multipart/form-data' }
        }
    })
}

//更新公司信息
export function updateCompany(data) {
    return request({
        url: 'api/v1/company/updateCompany',
        method: 'post',
        data
    })
}

//获取验证图片  以及token
export function getYzm(data) {
	return  request({
        url: '/api/public/getYzm',
        method: 'post',
        data
    })
}

//滑动或者点选验证
export function reqCheck(data) {
	return  request({
        url: '/api/captcha/check',
        method: 'post',
        data
    })
}