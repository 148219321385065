<template>

  <!-- <el-button style="margin-right:10px" type="success" @click="handRedImport()" size="small" plain>导入{{name}}</el-button> -->
  
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogFormVisible" width="30%" title="导入">
    <el-form size="small" label-position="right" label-width="5px">
      <el-form-item label="">
        <el-input style="width:60%;margin-right:10px" v-model="path"></el-input>
        <input size="small" ref="excel-upload-input" class="excel-upload-input" style="display:none" type="file" accept=".xlsx, .xls, .xml" @change="handleClick">
        <el-button type="primary" @click="handleUpload()" size="small">请选择文件</el-button>
      </el-form-item>
    </el-form>

    <!-- 导入组件 -->
    <good v-if="type == 'good'" @success="success" :rawFile="rawFile"></good>
    <buyer v-if="type == 'buyer'" @success="success" :rawFile="rawFile"></buyer>
    <company v-if="type == 'company'" @success="success" :rawFile="rawFile"></company>
    <inventory v-if="type == 'inventory'" @success="success" :rawFile="rawFile"></inventory>
    <daoruCustomer v-if="type == 'daoruCustomer'" @success="success" :rawFile="rawFile"></daoruCustomer>
  </el-dialog>
  


</template>
<script>
import company from '@/components/import/son/company';
import good from "@/components/import/son/good";
import buyer from '@/components/import/son/buyer';
import inventory from '@/components/import/son/inventory';
import daoruCustomer from '@/components/import/son/daoruCustomer';
export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
  },
  components: {
    good,
    buyer,
    company,
    inventory,
    daoruCustomer
  },
  data () {
    return {
      dialogFormVisible: false,
      rawFile: '',
      path: '',
    }
  },
  created () {
    
  },
  methods: {
    handRedImport(){
      this.dialogFormVisible = true
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    success(e){
      // this.$emit('success', e)
      this.$emit("handleSuccess",e);
      this.dialogFormVisible = false
    }
  }
}
</script>