<template>
  <!-- 新增科目弹窗 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close append-to-body title="新增科目" v-model="dialogVisible" width="35%" >
    <el-form :model="addData" label-width="120px" label-position="right" size="small">
      <el-form-item label="上级科目：">
        <el-select @change="changeCode" v-model="addData.subjectId" filterable placeholder="请选择" size="small" :disabled="disabled" style="width:200px">
          <el-option
            v-for="item in subjectListOldList"
            :key="item.id"
            :label="item.name3"
            :value="item.id"
          >
            <span style="float: left">{{ item.name3 }}</span>
            <span style="float: right;color: red;font-size: 14px;">{{item.fzhsName}}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <div v-if="addFzhsData.fzhsId == 0">
        <el-form-item label="科目名称：">
          <el-input v-model="addData.name" style="width:200px;" size="small"></el-input>
        </el-form-item>
        <el-form-item class="item" label="余额方向：">
          <el-radio v-model="addData.type" :label="1" :disabled="disabled">借</el-radio>
          <el-radio v-model="addData.type" :label="2" :disabled="disabled">贷</el-radio>
        </el-form-item>
      </div>
      <div v-else>
        <el-form-item label="辅助核算代码：">
          <el-input v-model="addFzhsData.code" style="width:80%;" size="small"></el-input>
        </el-form-item>
        <el-form-item label="辅助核算名称：">
          <el-input v-model="addFzhsData.name" style="width:80%;" size="small"></el-input>
        </el-form-item>
      </div>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel()" size="small">取 消</el-button>
        <el-button type="primary" @click="handleAddSure" size="small" :loading="isLoading">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { saveSubject, fzhsItemSave, newFzhsCode } from "@/api/subject"
export default {
  setup() {
    
  },
  props: {
    disabled: false,
  },
  computed: {
    subjectListOldList(){
      return this.$subjectFilterCode(this.codes, 2)
    }
  },
  data () {
    return {
      codes: [],
      dialogVisible:false,
      addData: {
        subjectId: undefined,
        name: "",
        type: 1
      },
      addFzhsData: {
        fzhsId: 0,//辅助核算项目id
        name: "",
        code: "",
      },
      isLoading:false
      
    }
  },
  methods: {
    cancel(){
      this.dialogVisible = false
      this.$emit("cancel")
    },
    init(data, codes){
      // console.log(data, codes,'data, codes')
      this.addData.subjectId = data.id
      this.addData.type = data.type
      this.addData.name = data.name
      if(codes){
        this.codes = codes
      }
      this.dialogVisible = true
    },
    changeCode(){
      let codes = this.subjectListOldList.filter(v=>{return this.addData.subjectId == v.id})
      if(codes.length != 0){
        this.addData.type = codes[0].type
        this.addFzhsData.subjectId = codes[0].id
        this.addFzhsData.fzhsId = codes[0].fzhsId
        if(codes[0].fzhsId != 0){
          newFzhsCode({fzhsId: codes[0].fzhsId}).then(res=>{
            this.addFzhsData.code = res.data.data.code
          })
        }
      }
    },
    //新增科目
    handleAddSure() {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      if(this.addFzhsData.fzhsId == 0){
        this.addSubjectSure()
      }else{
        this.addFzhsSure()
      }
      
    },
    addFzhsSure(){
      if (!this.addFzhsData.name) {
        this.$message.error("请输入辅助核算名称");
        return;
      }
      if (!this.addFzhsData.code) {
        this.$message.error("请输入辅助核算代码");
        return;
      }
      this.addFzhsData.code = this.addFzhsData.code
      this.addFzhsData.name = this.addFzhsData.name.trim(),
      this.isLoading = true
      fzhsItemSave([this.addFzhsData]).then(res => {
        this.isLoading = false
        if (res.data.msg == "success") {
          this.$qzfMessage("新增成功")
          this.dialogVisible = false
          this.$store.dispatch("commons/getSubjects").then(r=>{
            let info = res.data.data.info
            info.sign = this.addFzhsData.subjectId + "-" + info.id
            this.$emit("success", info)
          })
          this.$store.dispatch("commons/getBusinessType")
        }
      });
    },
    addSubjectSure(){
      if (!this.addData.name) {
        this.$message.error("请输入科目名称");
        return;
      }
      if (!this.addData.subjectId) {
        this.$message.error("请选择上级科目");
        return;
      }
      this.addData.subjectId = this.addData.subjectId * 1
      this.addData.name = this.addData.name.trim(),
      this.isLoading = true
      saveSubject(this.addData).then(res => {
        this.isLoading = false
        if (res.data.msg == "success") {
          this.$qzfMessage("新增成功")
          this.dialogVisible = false
          this.$store.dispatch("commons/getSubjects").then(r=>{
            let info = res.data.data.info
            info.sign = info.id + "-0"
            this.$emit("success", info)
          })
          this.$store.dispatch("commons/getBusinessType")
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select{
  margin:0
}
</style>