<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <a class="muban" href="https://file.listensoft.net/data/down/invoiceExcel/导入清单模板.xlsx" >通用模板下载</a>
    <upload-excel-component :rawFile='rawFile' :on-success="handleSuccess" :before-upload="beforeUpload" />
  </div>
</template>

<script>
import UploadExcelComponent from "@/components/import/son/index.vue";
import { importInvoiceQd } from "@/api/ea";
export default {
  components: { UploadExcelComponent },
    props: {
    rawFile: ""
  },
  
  data() {
    return {
      fullscreenLoading:false
    };
  },
  methods: {
    beforeUpload(file) {
      if(!file){
        this.$message({
          message: "请选择文件",
          type: "warning"
        });
        return false;
      }
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "请不要上传大于1M的文件。",
        type: "warning"
      });
      return false;
    },
    handleSuccess({ results, header }) {
      this.fullscreenLoading = true
      let items = [];
      // console.log(header);
      header.map(item => {
        item.map(v=>{
          let obj = {};
          obj.name = v[0]
          obj.unit = v[1]
          obj.spec = v[2]
          obj.count = v[3]
          obj.amount = v[4]
          obj.rate = v[5]
          obj.price = v[6]
          obj.ssflbm = v[7]
        //   obj.hsbz = v[8]
          obj.comId = this.$store.getters['user/comInfo'].id
          items.push(obj);
        })
      });
      items = items.slice(1)
      // console.log(items);
      importInvoiceQd(items).then(res=>{
        this.fullscreenLoading = false
        if(res.data.msg == "success"){
          this.$qzfMessage("导入成功", 3)
          this.$emit("success",res.data.data.list)
        }
      })
    }
  }
};
</script>


<style lang="scss" scoped>
.muban {
  text-align: left;
  font-size: 14px;
  color: red;
  display: block;
  width: 150px;
}
</style>
