<template>
  <el-dialog :close-on-click-modal="false"
    v-model="dialogVisible"
    title="到票状态"
    width="30%"
    destroy-on-close
  >
    <div style="width:100%;height:50px;text-align:center;margin-top:10px;border-bottom:1px dashed lightgray">
      <span>到票状态:</span>
      <el-select size="small" v-model="piaoStatus" @change="changeTicket">
        <el-option label="全部到票" :value="2"></el-option>
        <el-option label="部分到票" :value="1"></el-option>
        <el-option label="未到票" :value="9"></el-option>
      </el-select>
    </div>
    <div style="margin-top:20px">
      <el-checkbox-group v-model="piaoItems">
        <el-checkbox :disabled="optionStatus" label="进项发票"/>
        <el-checkbox :disabled="optionStatus" label="销项发票"/>
        <el-checkbox :disabled="optionStatus" label="费用发票"/>
        <el-checkbox :disabled="optionStatus" label="薪酬表"/>
        <el-checkbox :disabled="optionStatus" label="银行对账单"/>
      </el-checkbox-group>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="saveTicket()"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {getDpStatus , saveDpStatus} from "@/api/company"

export default {
  name:'ticketStatus',
  props:{
    comId: { //1客户 2供应商
      type:  Number,
      default: 0
    },
    period: {
      type: String,
      default: ""
    }
  },
  data(){
    return {
      dialogVisible:false,
      piaoItems:[],
      piaoStatus:9,
      optionStatus:false,
      params:{}
    }
  },
  methods:{
    ticketStatus(param){
      this.param = param
      getDpStatus(param).then(res=>{
        this.piaoStatus = res.data.data.piaoStatus
        this.piaoItems = res.data.data.piaoItems
        if(this.piaoStatus == 2 || this.piaoStatus == 9){
          this.optionStatus = true
        }
        this.$emit('success',this.piaoStatus)
      })
    },
    openDialog(){
      this.dialogVisible = true
    },
    saveTicket(){
      this.param.piaoItems = this.piaoItems
      this.param.piaoStatus = this.piaoStatus
      if(this.param.piaoItems.length == 0){
        this.param.piaoStatus = 9
        this.piaoStatus = 9
      }else if(this.param.piaoItems.length == 5){
        this.param.piaoStatus = 2
        this.piaoStatus = 2
      }
      saveDpStatus(this.param).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.dialogVisible = false
          this.$emit('success',this.piaoStatus)
        }
      })
    },
    changeTicket(){
      if(this.piaoStatus == 9){
        this.optionStatus = true
        this.piaoItems = []
      }else if(this.piaoStatus == 2){
        this.optionStatus = true
        this.piaoItems = ["进项发票", "销项发票", "费用发票", "薪酬表", "银行对账单"]
      }else {
        this.optionStatus = false
      }
    }
  }
}
</script>

<style>

</style>