import request from '@/utils/request'

// 新增存货
export function saveInventory(data) {
  return request({
    url: 'api/v2/inventory/saveInventory',
    method: 'post',
    data
  })
}

// 删除存货
export function delInventory(data) {
  return request({
    url: 'api/v2/inventory/delInventory',
    method: 'post',
    data
  })
}

// 存货列表
export function inventoryList(data) {
  return request({
    url: 'api/v2/inventory/inventoryList',
    method: 'post',
    data
  })
}

// 单位换算列表
export function inventoryUnits(data) {
  return request({
    url: 'api/v2/inventory/inventoryUnits',
    method: 'post',
    data
  })
}

// 编辑单位换算
export function inventoryUnitSave(data) {
  return request({
    url: 'api/v2/inventory/inventoryUnitSave',
    method: 'post',
    data
  })
}

// 存货账期列表
export function inventoryPeriodList(data) {
  return request({
    url: 'api/v2/inventory/inventoryPeriodList',
    method: 'post',
    data
  })
}

// 出入库明细
export function inventoryDetailList(data) {
  return request({
    url: 'api/v2/inventory/inventoryDetailList',
    method: 'post',
    data
  })
}

// 冲销列表
export function estimateList(data) {
  return request({
    url: 'api/v2/inventory/estimateList',
    method: 'post',
    data
  })
}

//冲销
export function estimateHc(data) {
  return request({
    url: 'api/v2/inventory/estimateHc',
    method: 'post',
    data
  })
}

// 暂估入库
export function estimateSave(data) {
  return request({
    url: 'api/v2/inventory/estimateSave',
    method: 'post',
    data
  })
}

// 其他出入库
export function inventorySave(data) {
  return request({
    url: 'api/v2/inventory/inventorySave',
    method: 'post',
    data
  })
}

// 存货核算绑定结转科目list
export function inventory1403CostCodeList(data) {
  return request({
    url: 'api/v2/inventory/inventory1403CostCodeList',
    method: 'post',
    data
  })
}

// 存货核算绑定结转科目
export function inventory1403CostCodeSave(data) {
  return request({
    url: 'api/v2/inventory/inventory1403CostCodeSave',
    method: 'post',
    data
  })
}

// 设置简易还是高级存货核算 info
export function inventoryCarrySettingInfo(data) {
  return request({
    url: 'api/v2/inventory/inventoryCarrySettingInfo',
    method: 'post',
    data
  })
}


// 设置简易还是高级存货核算
export function inventoryCarrySetting(data) {
  return request({
    url: 'api/v2/inventory/inventoryCarrySetting',
    method: 'post',
    data
  })
}

// 生产核算info
export function produceCheckInfo(data) {
  return request({
    url: 'api/v2/inventory/produceCheckInfo',
    method: 'post',
    data
  })
}
// 生产核算
export function produceCheck(data) {
  return request({
    url: 'api/v2/inventory/produceCheck',
    method: 'post',
    data
  })
}
// 重置领料加工
export function reverseProduce(data) {
  return request({
    url: 'api/v2/inventory/reverseProduce',
    method: 'post',
    data
  })
}


// 贸易核算info
export function tradeCheckInfo(data) {
  return request({
    url: 'api/v2/inventory/tradeCheckInfo',
    method: 'post',
    data
  })
}
// 贸易核算
export function tradeCheck(data) {
  return request({
    url: 'api/v2/inventory/tradeCheck',
    method: 'post',
    data
  })
}
// 重置贸易核算
export function reverseTrade(data) {
  return request({
    url: 'api/v2/inventory/reverseTrade',
    method: 'post',
    data
  })
}

// 委托加工info
export function processCheckInfo(data) {
  return request({
    url: 'api/v2/inventory/processCheckInfo',
    method: 'post',
    data
  })
}
// 委托加工
export function processCheck(data) {
  return request({
    url: 'api/v2/inventory/processCheck',
    method: 'post',
    data
  })
}
// 重置委托加工
export function reverseProcess(data) {
  return request({
    url: 'api/v2/inventory/reverseProcess',
    method: 'post',
    data
  })
}

// 服务成本核算info
export function fwCheckInfo(data) {
  return request({
    url: 'api/v2/inventory/fwCheckInfo',
    method: 'post',
    data
  })
}
// 服务成本核算
export function fwCheck(data) {
  return request({
    url: 'api/v2/inventory/fwCheck',
    method: 'post',
    data
  })
}
// 重置服务成本核算
export function reverseFw(data) {
  return request({
    url: 'api/v2/inventory/reverseFw',
    method: 'post',
    data
  })
}

// 工程核算info
export function projectCheckInfo(data) {
  return request({
    url: 'api/v2/inventory/projectCheckInfo',
    method: 'post',
    data
  })
}
// 工程核算
export function projectCheck(data) {
  return request({
    url: 'api/v2/inventory/projectCheck',
    method: 'post',
    data
  })
}
// 重置工程核算
export function reverseProject(data) {
  return request({
    url: 'api/v2/inventory/reverseProject',
    method: 'post',
    data
  })
}

// 存货核算主页面状态
export function inventoryCheckStatus(data) {
  return request({
    url: 'api/v2/inventory/inventoryCheckStatus',
    method: 'post',
    data
  })
}

// 出入库明细 撤销
export function delInventoryDetail(data) {
  return request({
    url: 'api/v2/inventory/delInventoryDetail',
    method: 'post',
    data
  })
}

// 存货初始化
export function importList(data) {
  return request({
    url: 'api/v2/inventory/importList',
    method: 'post',
    data
  })
}

//进销存明细
export function getDetailAccountInverntoryList(data) {
  return request({
    url: 'api/v2/inventory/getDetailAccountInverntoryList',
    method: 'post',
    data
  })
}

//重置存货初始化
export function delInventoryDetailInit(data) {
  return request({
    url: 'api/v2/inventory/delInventoryDetailInit',
    method: 'post',
    data
  })
}


