<template>
  <el-select size="small" filterable v-model="value" placeholder="请选择" clearable @clear="clearGsJm()">
    <el-option
      v-for="item in list"
      :key="item.id"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
</template>
<script>
// <jmxzdm type="small_vat_js" v-model:id="item.jmxzdm"></jmxzdm>
import { gsJmxzdm } from "@/api/taxCalculation"
export default {
  props: {
    type: '',
    id: "",
  },
  data () {
    return {
      list: [],
    }
  },
  computed: {
    value: {
      get() {
        if(!this.id){
          return ""
        }
        return this.id * 1
      },
      set(val) {
        this.$emit("update:id", val)
        this.$emit("success", val)
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init(){
      gsJmxzdm({jmType: this.type}).then(res=>{
        if(res.data.data.list){
          this.list = res.data.data.list
        }else{
          this.list = []
        }
      })
    },
    clearGsJm(){
      alert("xx")
      this.$emit("update:id", 0)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>