<template>
  <el-button style="margin-right:10px" type="success" @click="handExport()" size="small" plain>导出</el-button>
</template>

<script>
export default {
  name:'export',
  props:{
    from:{
      type:String,
      default:""
    },
  },
  data() {
    return {

    }
  },
  methods:{
    handExport(){
      // console.log(this.from);
    }
  }
}
</script>

<style>

</style>