import request from '@/utils/request'

//科目列表
export function findSubjects(data) {
  return request({
      url: 'api/v2/subject/findSubjects',
      method: 'post',
      data
  })
}

//会计科目列表
export function subjectTreeList(data) {
  return request({
      url: 'api/v2/subject/subjectTreeList',
      method: 'post',
      data
  })
}

//新增修改
export function saveSubject(data) {
  return request({
      url: 'api/v2/subject/saveSubject',
      method: 'post',
      data
  })
}

//删除
export function deleteEaSubject(data) {
  return request({
      url: 'api/v2/subject/deleteEaSubject',
      method: 'post',
      data
  })
}

// 辅助核算项目列表
export function fzhsList(data) {
  return request({
      url: 'api/v2/subject/fzhsList',
      method: 'post',
      data
  })
}

// 辅助核算项目明细列表
export function fzhsItemList(data) {
  return request({
      url: 'api/v2/subject/fzhsItemList',
      method: 'post',
      data
  })
}

// 新增 修改 辅助核算明细
export function fzhsItemSave(data) {
  return request({
      url: 'api/v2/subject/fzhsItemSave',
      method: 'post',
      data
  })
}

// 辅助核算明细删除
export function fzhsItemDel(data) {
  return request({
      url: 'api/v2/subject/fzhsItemDel',
      method: 'post',
      data
  })
}

// 会计科目 绑定 辅助核算
export function subjectBindFzhs(data) {
  return request({
      url: 'api/v2/subject/subjectBindFzhs',
      method: 'post',
      data
  })
}
// 会计科目 取消绑定 辅助核算
export function subjectCancelBindFzhs(data) {
  return request({
      url: 'api/v2/subject/subjectCancelBindFzhs',
      method: 'post',
      data
  })
}

// 辅助核算新增时生成code
export function newFzhsCode(data) {
  return request({
      url: 'api/v2/subject/newFzhsCode',
      method: 'post',
      data
  })
}

// 科目余额表
export function subjectBalanceList(data) {
  return request({
      url: 'api/v2/subject/subjectBalanceList',
      method: 'post',
      data
  })
}

// 辅助项目余额表
export function fzhsBalanceList(data) {
  return request({
      url: 'api/v2/subject/fzhsBalanceList',
      method: 'post',
      data
  })
}

// 绑定外币
export function subjectWbEdit(data) {
  return request({
      url: 'api/v2/subject/subjectWbEdit',
      method: 'post',
      data
  })
}

// 外币列表
export function wbList(data) {
  return request({
      url: 'api/v2/subject/wbList',
      method: 'post',
      data
  })
}

//科目余额修改保存
export function subjectBalanceSave(data) {
  return request({
      url: 'api/v2/subject/subjectBalanceSave',
      method: 'post',
      data
  })
}
//辅助核算新增
export function fzhsSave(data) {
  return request({
      url: 'api/v2/subject/fzhsSave',
      method: 'post',
      data
  })
}

//辅助核算删除
export function fzhsDel(data) {
  return request({
      url: 'api/v2/subject/fzhsDel',
      method: 'post',
      data
  })
}