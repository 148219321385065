<template>
  <el-icon @click="show()" class="iconStyleVideo"><VideoPlay /></el-icon><span @click="show()" class="wordStyle">使用说明</span>
  <div v-if="dialogVisible" class="player">
    <el-icon @click="dialogVisible = false" class="icon"><CircleClose /></el-icon>
    <div :id="'player' + vid">
      
    </div>
  </div>
  <!-- <qzf-video vid="4467be53742a8ad3bf591e6e5b250b83_4"></qzf-video> -->
  
</template>
<script>
export default {
  props: {
    vid: {
      type: String,
      default: "text",
    },
  },
  data () {
    return {
      dialogVisible: false,
    };
  },
  mounted(){
    
  },
  methods:{
    show(){
      this.dialogVisible = !this.dialogVisible
      let id = this.vid
      this.$nextTick(()=>{
        var player = polyvPlayer({
          wrap: "#player" + id,
          width: "1200px",
          height: "600px",
          vid:id, //云点播平台的视频唯一id。
        });
      })
      // setTimeout(function() {
        
      // }, 2000);
    }
  }
};
</script>
<style lang="scss" scoped>
.icon{
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 99999999999999999;
  display: block;
  color: #999;
  font-size: 30px;
  display: none;
  cursor: pointer;
}
.player:hover{
  .icon{
    display: block;
  }
  
}
.player{
  position: fixed;
  top: 44%;
  left: 37%;
  z-index: 9999999999;
  margin-left: -350px;
  margin-top: -200px;
}
.iconStyleVideo{
  position: relative;
  top: 3px;
  // font-size: 17px;
  // margin-right: 10px;
  color: #67c23a;
  cursor: pointer;
}
.wordStyle{
 margin-right: 17px;
 position: relative;
 top: 1px;
 color: #67c23a;
 margin-left: 3px;
 cursor: pointer;
}
</style>


