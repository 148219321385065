<template>
 <div class="wrap">
    <el-select style="width:180px" size="small" @change="changeCode" v-model="code" placeholder="选择发票类型">
      <el-option
        v-for="item in list"
        :key="item.code"
        :label="item.name"
        :value="item.code"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
// <invoice-type v-model:code="temp.fplx"></invoice-type>
export default {
  name: 'invoiceType',
  props: {
    code: '',
  },
  data() {
    return {
      list: [],
      // code: '',
    }
  },
  computed: {
    listStr(){
      return this.$store.getters['user/comInfo'].fplxdm
    }
  },
  watch:{
    listStr(){
      this.getList()
    },
  },
  created () {
    this.getList()
  },
  methods: {
    changeCode(){
      this.$emit('update:code', this.code)
      this.$emit('success', this.code)
    },
    findName(code){
      if(code == '004'){
        return "增值税专用发票"
      }else if(code == '007'){
        return "增值税普通发票"
      }else if(code == '025'){
        return "卷式发票"
      }else if(code == '026'){
        return "电子发票"
      }else if(code == '005'){
        return "机动车发票"
      }else if(code == '006'){
        return "二手车销售统一发票"
      }else if(code == '028'){
        return "增值税电子专用发票"
      }
    },
    getList(){
      let list = []
      this.listStr.split(',').map(v=>{
        if(v){
          list.push({
            code: v,
            name: this.findName(v)
          })
        }
      })
      this.list = list
    },
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.wrap{
  float: left;
  margin-left: 2px;
}
</style>
