<template>
  <el-cascader
    size="small"
    :props="props"
    v-model="deptId"
    :options="options"
    @change="changeValue"
    clearable>
  </el-cascader>
</template>

<script>
import { getClassByTeacher } from '@/api/base'
export default {
  name: 'selectUser',
  props: {
    options: Array,
    deptId: Number,
  },
  data() {
    return {
      props: {
        label: 'name',
        value: 'id',
      },
      options:[],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      getClassByTeacher({}).then(res => {
        this.options = res.data.data.list[0].children
      })
    },
    changeValue(val) {
      console.log(val);
      if(val){
        this.$emit('update:deptId', val[val.length-1])
      }else{
        this.$emit('update:deptId', val)
      }
      this.$emit("success", this.deptId)
    }
  }
}
</script>

<style scoped>
  
</style>
<!-- 参数type  回调方法select -->