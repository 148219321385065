<template>
  <!-- <el-select filterable v-model="id" placeholder="选择科目" size="small">
    <el-option style="color:red" label="新增+" value="add"></el-option>
    <el-option
      v-for="item in subjectListOldList"
      :key="item.sign"
      :label="item.name3"
      :value="item.sign"
    >
    <span style="float: left">{{ item.name3 }}</span>
    <span style="float: right;color: red;font-size: 14px;">{{item.fzhsItemName}}</span>

    </el-option>
  </el-select> -->

  <el-select :class="{border_none:borderNone}" @change="changeSelect" size="small" clearable filterable v-model="id" placeholder="请选择" @focus="selectFocus">
    <el-option-group
      v-for="group in options"
      :key="group.label"
      :label="group.label">
      <el-option
        v-for="item in group.options"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-option-group>
  </el-select>


  <add-inventory @success="addSuccess" ref="addInventory"></add-inventory>
  <!-- <inventory-list inventoryName="xxx" v-model:inventoryId="a" v-model:inventoryType="b" :type="true"></inventory-list> -->
</template>
<script>
export default {
  props:{
    type: {//是否使用服务
      type: Boolean,
      default: false
    },
    inventoryId: {
      type: Number,
      default: 0
    },
    inventoryName: {//默认新增存货名称
      type: String,
      default: ""
    },
    inventorySpec: {//默认新增存货型号
      type: String,
      default: ""
    },
    inventoryUnit: {//默认新增存货单位
      type: String,
      default: ""
    },
    inventoryType: {//存货还是服务  inventory service
      type: String,
      default: ""
    },
    borderNone:{
      type:Boolean,
      default:false
    },
    status: {//默认下拉框的code
      type: String,
      default: ""
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    options(){
      if(this.status != "" && this.ss != this.index && this.id != 0 && this.options && this.options.length != 0){
        return
      }
      if(this.status != "" && this.ss != this.index && this.id == 0){
        return
      }
      let inventorysV = this.$store.getters["commons/inventorys"]
      let inventorys = {}
      if(this.ss == ""){
        inventorys = inventorysV
      } else {
        if(this.ss == this.index){
          inventorys = inventorysV
        } else {
          inventorys.services = inventorysV.services?inventorysV.services.filter(v=>{return v.id == this.id}): []
          inventorys.inventoryYcl = inventorysV.inventoryYcl?inventorysV.inventoryYcl.filter(v=>{return v.id == this.id}): []
          inventorys.inventoryKcsp = inventorysV.inventoryKcsp?inventorysV.inventoryKcsp.filter(v=>{return v.id == this.id}): []
          inventorys.inventoryBcp = inventorysV.inventoryBcp?inventorysV.inventoryBcp.filter(v=>{return v.id == this.id}): []
          inventorys.inventoryBzw = inventorysV.inventoryBzw?inventorysV.inventoryBzw.filter(v=>{return v.id == this.id}): []
        }
      }
      let options = []
      if(inventorys.services && this.type){
        options.push({
          label: "服务",
          options: inventorys.services,
        })
      }
      options.push({
        label: '',//不填 新增存货
        options: [{
          id: "add",
          name: '+新增存货',
        }]
      })
      if(inventorys.inventoryYcl){
        options.push({
          label: "原材料",
          options: inventorys.inventoryYcl,
        })
      }
      if(inventorys.inventoryKcsp){
        options.push({
          label: "库存商品",
          options: inventorys.inventoryKcsp,
        })
      }

      if(inventorys.inventoryBcp){
        options.push({
          label: "半成品",
          options: inventorys.inventoryBcp,
        })
      }

      if(inventorys.inventoryBzw){
        options.push({
          label: "包装物",
          options: inventorys.inventoryBzw,
        })
      }
      if(inventorys.inventoryWtjgwz){
        options.push({
          label: "委托加工物资",
          options: inventorys.inventoryWtjgwz,
        })
      }
      if(inventorys.services && !this.type){
        options.push({
          label: "服务",
          options: inventorys.services,
        })
      }
      return options 
    },
    ss: {
      get(){
        return this.status
      },
      set(val){
        this.$emit("update:status", val)
      }
    },
    id: {
      get() {
        if(!this.inventoryId){
          return ""
        }
        return this.inventoryId
      },
      set(val) {
        if(val == 'add'){
          let data = {
            id: undefined,
            name: this.inventoryName,
            spec: this.inventorySpec,
            unit: this.inventoryUnit,
            type: 2,
          }
          this.$refs.addInventory.init(data)
          val = undefined
          return
        }
        this.$emit("update:inventoryId", val)
        if(val <= 10){
          this.$emit("update:inventoryType", 'service')
        }else{
          this.$emit("update:inventoryType", 'inventory')
        }

        let info = {}
        this.options.map(v=>{
          v.options.map(z=>{
            if(z.id == val){
              info = z
            }
          })
        })
        this.$emit("update:spec", info.spec)
        this.$emit("update:unit", info.unit)
        this.$emit("success", val)
      }
      
    }
  },
  data () {
    return {
      
    }
  },
  methods: {
    selectFocus(){
      if(this.status == ""){
        return
      }
      console.log(11)
      this.ss = String(this.index)
    },
    addSuccess(e){
      this.id = e.id
    },
  }
}
</script>