<template>
  <div :loading="isLoading">
    <div class="load_box" v-loading.fullscreen.lock="fullscreenLoading">
      <el-select v-model="value" placeholder="请选择导入模板" size="small" style="width:46%" filterable >
        <el-option
          v-for="item in urls"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <a href="javascript:;" @click="loadValue(value)" >下载模板</a>
    </div>
    <div style="text-align:right">
      <el-button size="small" type="primary" @click="uploadExcel" :loading="isLoading">上传</el-button>
    </div>
    
    <!-- <upload-excel-component :rawFile='rawFile' :on-success="handleSuccess" :before-upload="beforeUpload" /> -->
  </div>
</template>

<script>
// import UploadExcelComponent from "@/components/import/son/index.vue";
import { importExcel } from "@/api/import"
import { saveEaCompany } from "@/api/company";
export default {
  name: "UploadFee",
  // components: { UploadExcelComponent },
  props: {
    rawFile: ""
  },
  data() {
    return {
      isLoading:false,
      dialogTableVisible:false,
      fullscreenLoading:false,
      urls: [{
          value: 'https://file.listensoft.net/data/v2/customer/青岛客户导入模板.xlsx',
          label: '青岛客户导入模板'
        }, {
          value: 'https://file.listensoft.net/data/v2/customer/山东客户导入模板.xlsx',
          label: '山东客户导入模板'
        }, {
          value: 'https://file.listensoft.net/data/v2/customer/山西客户导入模板.xlsx',
          label: '山西客户导入模板'
        }, {
          value: 'https://file.listensoft.net/data/v2/customer/内蒙客户导入模板.xlsx',
          label: '内蒙客户导入模板'
        }, {
          value: 'https://file.listensoft.net/data/v2/customer/北京客户导入模板.xlsx',
          label: '北京客户导入模板'
        }, {
          value: 'https://file.listensoft.net/data/v2/customer/河南客户导入模板.xlsx',
          label: '河南客户导入模板'
        }, {
          value: 'https://file.listensoft.net/data/v2/customer/西藏客户导入模板.xlsx',
          label: '西藏客户导入模板'
        }, {
          value: 'https://file.listensoft.net/data/v2/customer/江苏客户导入模板.xlsx',
          label: '江苏客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/江苏客户导入模板(政务网).xlsx',
          label: '江苏客户导入模板(政务网)'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/青海客户导入模板.xlsx',
          label: '青海客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/云南客户导入模板.xlsx',
          label: '云南客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/天津客户导入模板.xlsx',
          label: '天津客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/安徽客户导入模板.xlsx',
          label: '安徽客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/海南客户导入模板.xlsx',
          label: '海南客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/重庆客户导入模板.xlsx',
          label: '重庆客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/上海客户导入模板.xlsx',
          label: '上海客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/河北客户导入模板.xlsx',
          label: '河北客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/湖南客户导入模板.xlsx',
          label: '湖南客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/大连客户导入模板.xlsx',
          label: '大连客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/福建客户导入模板.xlsx',
          label: '福建客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/甘肃客户导入模板.xlsx',
          label: '甘肃客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/广东客户导入模板.xlsx',
          label: '广东客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/广西客户导入模板.xlsx',
          label: '广西客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/贵州客户导入模板.xlsx',
          label: '贵州客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/黑龙江客户导入模板.xlsx',
          label: '黑龙江客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/吉林客户导入模板.xlsx',
          label: '吉林客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/江西客户导入模板.xlsx',
          label: '江西客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/辽宁客户导入模板.xlsx',
          label: '辽宁客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/宁波客户导入模板.xlsx',
          label: '宁波客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/宁夏客户导入模板.xlsx',
          label: '宁夏客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/厦门客户导入模板.xlsx',
          label: '厦门客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/陕西客户导入模板.xlsx',
          label: '陕西客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/深圳客户导入模板.xlsx',
          label: '深圳客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/四川客户导入模板.xlsx',
          label: '四川客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/浙江客户导入模板.xlsx',
          label: '浙江客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/湖北客户导入模板.xlsx',
          label: '湖北客户导入模板'
        },{
          value: 'https://file.listensoft.net/data/v2/customer/新疆客户导入模板.xlsx',
          label: '新疆客户导入模板'
        }
      ],
      value: ''
    };
  },
  created(){
  },
  methods: {
    uploadExcel(){
      this.isLoading = true
      const param = new FormData() // 创建form对象
      param.append('file', this.rawFile, this.rawFile.name)
      importExcel(param).then(res=>{
          if(res.data.msg == "success"){
            this.header = res.data.data.data[0].data
            this.handleSuccess(this.header)
          }
        })
    },
    loadValue(e){
      if(e == ''){
        this.$message({
          message: "请选择模板",
          type: "warning"
        });
        return
      } else {
        window.open(this.value + "?" + new Date().getTime())
      }
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "请不要上传大于1M的文件。",
        type: "warning"
      });
      return false;
    },
    handleSuccess(header) {
      let source = header.slice(2)
      let info = [];
      // console.log(header);
      source.map(v=>{
        if(v[1]){
          let yue = v[14]
          if(yue < 11){
            yue = "0" + yue
          }
          if (!v[6]) {
            this.$message({
              message: v[1] + "请填写纳税人识别号",
              type: "warning"
            });
            return
          }
          if (v[6].length < 15) {
            this.$message({
              message: v[1] + "纳税人识别号错误",
              type: "warning"
            });
            return
          }
          info.push({
            sortCom:v[0]?v[0].toString():'',
            name: v[1].toString(),
            type: v[2] == '增值税一般纳税人'?'2':'1',
            remark: v[3].toString(),
            districtCode: v[3].toString(),
            accountSystem: v[4].toString(),
            companyType: v[5].toString(),
            taxNo: v[6].toString(),
            idType: "纳税人识别号",
            loginMethod: v[7].toString(),

            idNo: v[8]?v[8].toString():'',
            password: v[9]?v[9].toString():'',
            personalLoginType: v[10]?v[10].toString():'',
            personalIdno: v[11]?v[11].toString():'',
            personalTaxPwd: v[12]?v[12].toString():"",
            startAccountPeriod: v[13].toString() + yue.toString(),
            manager: '',
            zzrxm: v[15]?v[15].toString():'',
            zzrmn: v[17]?v[17].toString():'',
            zzridno: v[16]?v[16].toString():'',
            xzsf: v[18],
            mobileRec: v[19]?String(v[19]):"",
          })
        }
      })
      saveEaCompany({list:info}).then(res => {
        this.isLoading = false
        if (res.data.data.list !== '') {
          this.$emit("success",res.data.data);
        } else  {
          this.$emit("success",res.data.data);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.load_box{
  text-align: left !important;
  a{
    transform: translateY(-1px);
    display: inline-block;
    padding: 0 20px;
    text-align: center;
    line-height: 28px;
    font-size: 14px;
    color: #fff;
    background: #67c23a;

  }
}
</style>
