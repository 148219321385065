<template>
  <div class="wrap">
    <el-select v-model="recentYear" @change="yearChange" placeholder="请选择" clearable size="small">
      <el-option
        v-for="item in years"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>
<script>
  export default {
    props: {
      recentYear: '',
    },
    data(){
      return{
        years:[],
        recentYear:''
      }
    },
    methods:{
      init(){
        var myDate = new Date;
        var year = myDate.getFullYear();//获取当前年
        this.initSelectYear(year)
        this.recentYear = year.toString();
        this.$emit('update:recentYear', this.recentYear)
      },
      initSelectYear(year){
        this.years = [];
        for(let i=0;i<10;i++){
          this.years.push({value:(year - i),label:(year - i)});
        }
      },
      yearChange(value){
        this.recentYear = value.toString()
        this.$emit('update:recentYear', this.recentYear)
      },
    },
    created() {
      this.init()
    }
  }
</script>
<style scoped>
.wrap{
  float: left;
  margin-left: 5px;
}
</style>