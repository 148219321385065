<template>
  <div class="big">
    <div class="bottom">
      <div class="right">
        <div class="top">
          <headers></headers>
        </div>
        <div class="leftFirst" v-if="!this.comId">
          <leftFirst></leftFirst>
        </div>
        <div class="leftSecond" v-if="this.comId">
          <leftSecond></leftSecond>
        </div>
        <div :class="!this.comId?'left1':'left'">
          <left></left>
        </div>
        <div :class="!this.comId?'rightStyle':'rightyStyle1'">
          <tags></tags>
          <right></right>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import headers from './components/header.vue'
import left from './components/left.vue'; 
import leftFirst from './components/leftFirst.vue'; 
import leftSecond from './components/leftSecond.vue'; 

import right from './components/right.vue'; 
import tags from './components/tags.vue'; 
export default {
  name: 'layout',
  data() {
    return {
      comId: this.$store.getters["user/comInfo"].comId * 1,
    }
  },
  components: {
    left,
    right,
    tags,
    headers,
    leftFirst,
    leftSecond
  },
  setup() {
    
  },
  created () {
    
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.big{
  // height: 100%;
  height: calc(100vh - 11px);
  .top{
    width: 100%;
    height: 70px;
    background: #ccc;
  }
  .bottom{
    width: 100%;
    // height: calc(100% - 56px);
    height: 100%;

    .left{
      float: left;
      width: 170px;
      height: 94%;
      overflow-y: auto;
      overflow-x: hidden;
      
    }
    .left::-webkit-scrollbar {
        width: 4px;
      }
      /*滚动条里面小方块*/
      .left::-webkit-scrollbar-thumb {
		  border-radius: 5px;
		  // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		  background: rgba(100, 100, 100, 0.2);
		}
    .left1{
      float: left;
      width: 162px;
      height: 92%;
    }
    .leftFirst{
      float: left;
      width: 70px;
      height: 94%;
    }
    .leftSecond{
      float: left;
      width: 25px;
      height: 94%;
    }
    .right{
      float: left;
      width: calc(100% - 1px);
      // 变量
      height: 98%;
      background: #efefef;
      .rightStyle{
        float:right;
        width: calc(100% - 232px)
      }
      .rightyStyle1{
        float:right;
        width: calc(100% - 206px)
      }
    }
  }
}

</style>