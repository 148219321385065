import invoiceType from "@/components/invoiceType";
import addGoods from "@/components/Goods/addGoods"
import addBuyer from "@/components/Buyer/addBuyer"
import addSeller from "@/components/Seller/addSeller"
import invoiceDetail from "@/components/invoiceDetail"
import qzfImport from "@/components/import/index"
import qzfImportBack from "@/components/import/indexBack"
import Pagination from "@/components/Pagination";
import choiceYear from "@/components/choiceYear";
import choiceQuarter from "@/components/choiceQuarter";
import choiceMonth from "@/components/choiceMonth";

import selectuser from "@/components/Screening/selectuser";

import selectClass from "@/components/Screening/selectClass"

import timeSection from "@/components/timeSection";
import period from "@/components/period";

import addSubject from "@/components/subject/addSubject";
import subjectList from "@/components/subject/subjectList";
import subjectListAll from "@/components/subject/subjectListAll";

import select from "@/components/qzf/select";
import qzfButton from "@/components/qzf/qzf-button";
import qzfInput from "@/components/qzf/qzf-input";
import qzfTab from "@/components/qzfTab/tab";
import qzfVideo from "@/components/qzf/qzf-video";

import addInventory from "@/components/Inventory/addInventory"
import addBusiness from "@/components/business/addBusiness"
import corpList from "@/components/business/corpList"
import inventoryList from "@/components/Inventory/inventoryList"

import businessType from "@/components/business/businessType"

import settings from "@/components/setting/settings"

import voucherShow from "@/components/voucher/voucherShow"
import jmxzdm from "@/components/tax/jmxzdm"


import qzfStrTd from "@/components/table/qzf-str-td";
import qzfTd from "@/components/table/qzf-td";
import search from "@/components/search/search";

import qzfExport from  "@/components/export/index"
import qzfTabs from  "@/components/tabs/qzfTabs"
import qzfTabsItem from  "@/components/tabs/qzfTabsItem"
import ticketStatus from  "@/components/ticketStatus"

import printSetting from "@/components/printSetting/printSetting"
 
import qzfSearchPeriod from "@/components/searchPeriod/searchPeriod"
export default{
  install (Vue, options) {
    Vue.component('invoice-type',invoiceType);//发票类型代码
    Vue.component('add-goods',addGoods);//新增商品
    Vue.component('add-buyer',addBuyer);//新增购方
    Vue.component('add-seller',addSeller);//新增销方
    Vue.component('invoice-detail',invoiceDetail);//发票详情
    Vue.component('qzf-import',qzfImport);//导入
    Vue.component('qzf-import-back',qzfImportBack);//后端导入
    
    Vue.component('qzf-pagination',Pagination);
    Vue.component('choice-year',choiceYear);
    Vue.component('choice-quarter',choiceQuarter);
    Vue.component('choice-month',choiceMonth);
    Vue.component('qzf-time',timeSection);
    
    Vue.component('qzf-period',period);//账期
    Vue.component('add-subject',addSubject);//添加科目
    
    Vue.component('subject-list',subjectList);//科目列表
    Vue.component('subject-list-all',subjectListAll);
    

    Vue.component('qzf-select',select);//凭证使用的科目列表

    Vue.component('qzf-button',qzfButton);//按钮
    Vue.component('qzf-input',qzfInput);//按钮
    Vue.component('qzf-tab',qzfTab);//tab
    Vue.component('qzf-video',qzfVideo);//按钮

    
    Vue.component('add-inventory',addInventory);//新增存货
    Vue.component('inventory-list',inventoryList);//存货list
    
    Vue.component('add-business',addBusiness);//新增客户/供应商
    Vue.component('corp-list',corpList);//客户/供应商 list

    Vue.component('business-type',businessType);//基础配置

    Vue.component('settings',settings);//基础配置

    Vue.component('voucher-show',voucherShow);//生成凭证

    Vue.component('qzf-str-td',qzfStrTd); //str-td

    Vue.component('qzf-td',qzfTd);//td

    Vue.component('search',search);//查询
    Vue.component('jmxzdm',jmxzdm);//减免性质代码

    Vue.component('qzf-export',qzfExport);//导出

    
    Vue.component('qzf-tabs',qzfTabs);//tabs
    Vue.component('qzf-tabs-item',qzfTabsItem);//tabs-items
    Vue.component('ticket-status',ticketStatus);//ticket-status
    Vue.component('qzf-search-period',qzfSearchPeriod); //qzfSearchPeriod

    Vue.component('print-setting',printSetting);//ticket-status
    Vue.component('selectuser',selectuser);
    
    Vue.component('selectClass',selectClass)
  }
}