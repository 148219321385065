<template>
  <el-select @change="changeValue" v-model="userId" multiple collapse-tags :placeholder="placeholder"  size="small" filterable clearable>
    <el-option
      key="0"
      :label="defaultOption.CnName"
      :value="defaultOption.id"
    >
    </el-option>
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.CnName"
      :value="item.id" size="small">
    </el-option>
  </el-select>
</template>

<script>
import { findPeople } from '@/api/base'
export default {
  name: 'selectUser',
  props: {
    options: Array,
    // userId: Array,
    code: '',
    eduCode:'',
    deptId:0
  },
  data() {
    return {
      userId:[],
      options:[],
      defaultOption:{
        CnName:'全部',
        id:0
      },
      placeholder:'请先选择班级'
    }
  },
  mounted() {
    // this.init()
  },
  methods: {
    init(deptId){
      if(deptId){
        this.placeholder = '请选择人员'
        findPeople({code:this.code,eduCode:this.eduCode,deptId:deptId}).then(res => {
          this.options = res.data.data.list
          this.$emit("success", this.options)
        })
      }else{
        this.placeholder='请先选择班级'
      }
    },
    changeValue(val) {
      if(val[0] != 0){
        this.$emit('update:userId', this.userId)
      }else{
        this.$emit("success", this.options)
      }
    },
    clearValue(){
      this.userId = null
    }
  }
}
</script>

<style scoped>
  
</style>
<!-- 参数type  回调方法select -->