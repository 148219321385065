import request from '@/utils/request'

// 资产列表
export function assetList(data) {
  return request({
    url: 'api/v2/asset/assetList',
    method: 'post',
    data
  })
}

// 新增资产
export function saveAsset(data) {
  return request({
    url: 'api/v2/asset/saveAsset',
    method: 'post',
    data
  })
}

// 删除资产
export function deleteEaAssets(data) {
  return request({
    url: 'api/v2/asset/deleteEaAssets',
    method: 'post',
    data
  })
}

// 结转固定资产
export function jzAsset(data) {
  return request({
    url: 'api/v2/asset/jzAsset',
    method: 'post',
    data
  })
}

// 一键折旧完   取消折旧
export function cancelAsset(data) {
  return request({
    url: 'api/v2/asset/cancelAsset',
    method: 'post',
    data
  })
}

// 一键正常 一键暂停
export function assetTotalSaves(data) {
  return request({
    url: 'api/v2/asset/assetTotalSaves',
    method: 'post',
    data
  })
}

// 固定资产清理
export function clearAsset(data) {
  return request({
    url: 'api/v2/asset/clearAsset',
    method: 'post',
    data
  })
}