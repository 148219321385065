<template>
  <el-dialog :close-on-click-modal="false"
    :title="titleType"
    v-model="dialogVisible"
    width="30%"
    size="small"
    destroy-on-close
    append-to-body
  >
    <el-form :model="form" :rules="rules" ref="ruleForm" size="small" style="text-align:left">
      <el-form-item label="名称" :label-width="formLabelWidth" prop="name">
        <el-input v-model="form.name" autocomplete="off" size="small" ></el-input>
      </el-form-item>
      <el-form-item label="类别" :label-width="formLabelWidth" prop="type">
        <el-select v-model="form.type" placeholder="请选择类别" size="small" @change="changeCode">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="科目" :label-width="formLabelWidth" prop="subjectId">
        <subject-list subjectName="" v-model:subjectId="form.subjectId" :codes="$findCode(code)" ></subject-list>
      </el-form-item>
      <el-form-item label="单位" :label-width="formLabelWidth">
        <el-input v-model="form.unit" autocomplete="off" size="small"></el-input>
      </el-form-item>
      <el-form-item label="型号" :label-width="formLabelWidth">
        <el-input v-model="form.spec" autocomplete="off" size="small"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <qzf-button size="small" @success="cancel()">取 消</qzf-button>
        <qzf-button size="small" type="primary" @success="addInventorySure">确 定</qzf-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { saveInventory } from '@/api/inventory'
export default {
  name:'addInventory',
  data() {
    return{
      dialogVisible:false,
      form: {
      },
      titleType: '存货',
      listQuery:{
        id: 456,//456
        id2: 233,//233
      },
      code:['code1285','code1403','code1405','code1406','code1408','code1411','code1412','code1421','code1605'],
      formLabelWidth: '90px',
      options:[
        {
          value:1,
          label:'原材料'
        },
        {
          value:2,
          label:'库存商品'
        },
        {
          value:3,
          label:'半成品'
        },
        {
          value:4,
          label:'委托加工物资'
        },
        {
          value:5,
          label:'周转材料'
        },
        {
          value:6,
          label:'包装物'
        },
        {
          value:7,
          label:'消耗性生物资产'
        },
        {
          value:8,
          label:'工程物资'
        },
        {
          value:9,
          label:'开发产品'
        },
        {
          value:10,
          label:'发出产品'
        },
        {
          value:11,
          label:'其他'
        },
      ],
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '请选择类别', trigger: 'change' },
        ],
        subjectId: [
          { required: true, message: '请选择科目', trigger: 'change' },
        ],
      },
    }
  },
  methods:{
    cancel(){
      this.dialogVisible = false
      this.$emit("cancel")
    },
    init(row){
      this.dialogVisible = true
      // console.log("row", row)
      if(row){
        !row.type ? row.type = 2 : row.type
        this.form = row
      }else{
        this.form = {
          type: 2,
        }
      }
    },
    addInventorySure(){
      // console.log(this.form);
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          saveInventory(this.form).then(res=>{
            // console.log(res);
            if(res.data.msg == "success"){
              this.dialogVisible = false
              this.$qzfMessage("设置成功")
              this.$store.dispatch("commons/getInventory")
              this.$store.dispatch("commons/getBusinessType")
              this.$bus.emit('inventoryManageUpdate')
              this.$bus.emit('inventoryPeriodUpdate')
              this.$emit("success",res.data.data.info)
            }
          })
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    changeCode(){
    },
    changeSubject(e){
      // console.log(e);
    }
  }

}
</script>

<style lang="scss" scoped>
.el-select{
  width: 80%;
  margin-left: 0px;
}
.el-input{
  width: 80%;
}
</style>