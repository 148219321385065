// import  asyncRouterMap from '@/router/routerDt'
import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import { getToken, removeToken } from '@/utils/auth' // getToken from cookie

let asyncRouterFlag = 0
const whiteList = ['/login']// no redirect whitelist

//动态添加路由
const getRouter = async(e) => {
  let asyncRouterMap = []
  await store.dispatch('router/SetAsyncRouter',e)
  const asyncRouters = store.getters['router/routerList']
  asyncRouters.map(asyncRouter => {
    router.addRoute(asyncRouter.name,asyncRouter)
  })
}

router.beforeEach((to, from, next) => {
  //记录下from 的页面
  NProgress.start() // start progress bar
  // alert(getToken())
  if (getToken()) { // determine if there has token
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      // 添加flag防止多次获取动态路由和栈溢出
      if (!asyncRouterFlag) {
        asyncRouterFlag++
        store.dispatch('user/GetUserInfo').then(res=>{
          //权限
          // getRouter(res.user.isAdmin)
          getRouter(res.roles)
          //该干嘛干嘛正常
          // next({ ...to, replace: true })//跳转当前路由也

          if(res.roleName == "accountBook"){
            next({ path: '/etaxHome' })//跳转根目录
          }else if(res.roleName == "etax"){
            next({ path: res.indexPath })//跳转根目录
          }
          
        }).catch(err=>{
          //有问题 返回登录页面 直接清空token 就可
          // removeToken()
          // location.reload()
        })
        
      } else {
        //这个地方还得判断有没有此页面 没有 搞个404
        if(to.matched.length === 0){
          next({ path: '/404' })
        }
        next()
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
      //这个地方还得判断有没有此页面 没有 搞个404
      next()
    } else {
      next(`/login`) // 否则全部重定向到登录页
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})