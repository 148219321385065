<template>
  <div class="left-big">
    <!-- <div style="height: 56px;">
    </div> -->
    <el-menu
      default-active="2"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
    >
      <!-- <el-menu-item :class="{cur:item.value == thisNav}" v-for="(item, index) in nav" :key="index" @click="goEtax(item.value, false)">
        <span class="iconfontStyle">
          <i class="iconfont icon-xinzengkehu" v-if="item.value == '初始建账'">
            <span class="spanName" style="left: 18px;">初始化</span>
            <i class="el-icon-caret-left" :class="{singleSngele:item.value == thisNav,singleSngele2:item.value != thisNav}"></i>
          </i>
          <i class="iconfont icon-jizhangkehu" v-if="item.value == '记账核算'">
            <span class="spanName">记  账</span>
            <i class="el-icon-caret-left" :class="{singleSngele:item.value == thisNav,singleSngele2:item.value != thisNav}"></i>
          </i>
          <i class="iconfont icon-dianzishuiju" v-if="item.value == '批量申报'">
            <span class="spanName">申  报</span>
            <i class="el-icon-caret-left" :class="{singleSngele:item.value == thisNav,singleSngele2:item.value != thisNav}"></i>
          </i>
          <i class="iconfont icon-kehuguanli" v-if="item.value == '客服'">
            <span class="spanName">客  服</span>
            <i class="el-icon-caret-left" :class="{singleSngele:item.value == thisNav,singleSngele2:item.value != thisNav}"></i>
          </i>
          <i class="iconfont icon-gongsi" v-if="item.value == '平台'">
            <span class="spanName">平  台</span>
            <i class="el-icon-caret-left" :class="{singleSngele:item.value == thisNav,singleSngele2:item.value != thisNav}"></i>
          </i>
        </span>
      </el-menu-item> -->
    </el-menu>
  </div>
</template>

<script>
import store from '@/store'
export default {
  name: 'left',
  data () {
    return {
      // opens: ["/index", "/setting"], // 设置菜单栏展开, 默认展开子菜单 
      opens: [0, 1, 2, 3, 4, 5], // 设置菜单栏展开, 默认展开子菜单 
      routers: store.getters['router/asyncRouters'],
      settingShow:false,

    }
  },
  computed: {
    routers(){
      return store.getters['router/asyncRouters']
    },
    thisNav(){
      return store.getters['user/model']
    },
    nav(){
      let nav = []
      let nav2 = []
      if(this.$store.getters["user/user"].primaryRouting){
        nav = this.$store.getters["user/user"].primaryRouting.map(v=>{
          if(v == '管理设置'){
            this.settingShow = true
            return
          }
          nav2.push({
            name:v,
            value:v
          })
        })
      }
      return nav2
    },
  },
  created () {
    // console.log(this.routers)
    this.routers.map(v=>{
      // console.log(v)
    })
  },
  methods: {
    handleClose(key, keyPath) {
      this.$refs.menus.open(keyPath);
    },
    goEtax(e, force){
      //为了防止重复调用 userInfo 做个判断
      if(store.getters["user/model"] == e && !force){
        this.gogogo(e)
        return
      }
      this.$store.dispatch("user/setModel", e)
      this.$store.dispatch('user/GetUserInfo').then(res=>{
        // getRouter(res.roles)
        this.$store.dispatch('router/SetAsyncRouter',res.roles).then(res=>{
          this.$store.dispatch('tagsView/delAllViews')
          this.gogogo(e)
        })
      }).catch(err=>{
        //有问题 返回登录页面 直接清空token 就可
        // removeToken()
        // location.reload()
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.iconfont{
  font-size: 16px;
  margin-right: 10px;
}
.left-big{
  overflow-y: auto;
  height: 100%;
  // background: #ebf1f7;
  background: $color-main;
  span{
    font-size: 16px;
  }
}
.spanName{
  display: block;
  position: absolute;
  bottom: 0;
  font-size: 10px !important;
  left: 21px;
}
/*滚动条宽度*/
      .left-big::-webkit-scrollbar {
        width: 4px;
      }
      /*滚动条里面小方块*/
      .left-big::-webkit-scrollbar-thumb {
		  border-radius: 5px;
		  // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		  background: rgba(100, 100, 100, 0.2);
		}
		/*滚动条里面轨道*/
		// .left-big::-webkit-scrollbar-track {
		//   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		//   border-radius: 0;
		//   background: rgba(0, 0, 0, 0.1);
		// }

</style>
<style lang="scss">
.router-link{
 text-decoration:none;
}
.el-sub-menu__title:hover{
   background-color: #dcedff !important; 
}
//子级的类名
// .el-menu-item:hover {
//   background-color: rgb(3, 19, 33) !important;
// }

</style>
<style lang="scss" scoped>
.el-sub-menu__title{
  background-color: $color-main !important;
  line-height:42px !important;
  height: 42px !important;
  font-weight: 500;
  font-size: 16px;
}
.el-menu-item{
  line-height:42px !important;
  height: 70px !important;
  width: 70px;
  font-weight: 500;
  font-size: 16px;
  background-color: $color-main;
  padding-left: 24px !important;
  padding-top: 5px;
}

</style>
<style lang="scss" scoped>
.cur{
  background-color: rgba($color: $color-main, $alpha: 0.8)
}
.singleSngele{
  position: relative;
  font-size:29px !important;
  right:-6px;
  top:9px
}
.singleSngele2{
  display: none;
}
.iconfontStyle i{
  color:#fff !important;
  font-size: 22px;
}
</style>