import request from '@/utils/request'

// 银行列表
export function bankNameList(data) {
  return request({
    url: 'api/v2/bank/bankNameList',
    method: 'post',
    data
  })
}

// 新增修改银行
export function saveBankName(data) {
  return request({
    url: 'api/v2/bank/saveBankName',
    method: 'post',
    data
  })
}

// 删除银行账户
export function bankNameDel(data) {
  return request({
    url: 'api/v2/bank/bankNameDel',
    method: 'post',
    data
  })
}


// 资金 - 银行
// 批量新增银行记录
export function saveBank(data) {
  return request({
    url: 'api/v2/bank/saveBank',
    method: 'post',
    data
  })
}

// 银行记录
export function bankList(data) {
  return request({
    url: 'api/v2/bank/bankList',
    method: 'post',
    data
  })
}

// 删除银行记录
export function delBank(data) {
  return request({
    url: 'api/v2/bank/delBank',
    method: 'post',
    data
  })
}

// 获取协议签订页面
export function agreement(data) {
  return request({
    url: 'api/v2/bank/agreement',
    method: 'post',
    data
  })
}

// 获取协议编号
export function icbcXyNo(data) {
  return request({
    url: 'api/v2/bank/icbcXyNo',
    method: 'post',
    data
  })
}

// 获取协议编号  资金-银行页面
export function getIcbcDz(data) {
  return request({
    url: 'api/v2/bank/getIcbcDz',
    method: 'post',
    data
  })
}

// 查看电子回单  资金-银行页面
export function bankPdf(data) {
  return request({
    url: 'api/v2/bank/bankPdf',
    method: 'post',
    data
  })
}