<template>
  <div class="wrap">
    <el-select v-model="month" placeholder="请选择月份" size="small" @change="monthChange">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>
<script>
  export default {
    data(){
      return{
        options: [
          {
            value: '01',
            label: '01',
          },
          {
            value: '02',
            label: '02',
          },
          {
            value: '03',
            label: '03',
          },
          {
            value: '04',
            label: '04',
          },
          {
            value: '05',
            label: '05',
          },
          {
            value: '06',
            label: '06',
          },
          {
            value: '07',
            label: '07',
          },
          {
            value: '08',
            label: '08',
          },
          {
            value: '09',
            label: '09',
          },
          {
            value: '10',
            label: '10',
          },
          {
            value: '11',
            label: '11',
          },
          {
            value: '12',
            label: '12',
          },
        ],
        month: '',
      }
    },
    methods:{
      monthChange(){
        this.$emit('update:month', this.month)
      }
    },
    created() {
    }
  }
</script>
<style scoped>
.wrap{
  float: left;
  margin-left: 5px;
}
</style>