<template>
<!-- 后端导入 -->
  <el-button v-if="!hideButton" type="success" @click="handImport()" size="small"   >
    <el-icon><FolderRemove /></el-icon><span  > 导入{{name}}</span>
  </el-button>
  
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogFormVisible" width="30%" title="导入">
    <el-form size="small" label-position="right" label-width="5px">
      <el-form-item label="">
        <el-input style="width:70%;margin-right:10px" v-model="path"></el-input>
        <input size="small" ref="excel-upload-input" class="excel-upload-input" style="display:none" type="file" accept=".xlsx, .xls, .xml" @change="handleClick">
        <el-button type="primary" @click="handleUpload()" size="small">请选择文件</el-button>
      </el-form-item>
      <el-form-item label="">
        <el-radio-group @change="clickQt()" v-model="mubanType">
          <el-radio label="2" v-if="this.from == '银行' || this.from == '科目' || this.from == '历史科目' || this.from == '历史凭证'">通用导入</el-radio>
          <el-radio label="1">模板导入</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" v-if="this.mubanType == '1'">
        <el-select size="small" v-model="type" placeholder="请选择" clearable @change="mubanChange" filterable>
          <el-option v-for="(item, index) in list" :key="index" :label="item.type" :value="item.type"></el-option>
        </el-select>
        <el-date-picker v-if="this.from == '历史科目'" v-model="period" type="month" placeholder="请选择账期" value-format="YYYYMM" format="YYYYMM"></el-date-picker>
        <el-date-picker
        v-if="this.from == '历史凭证'"
        size="small"
        v-model="changeTime"
        type="monthrange"
        range-separator="-"
        value-format="YYYYMM"
        format="YYYYMM"
        start-placeholder="开始账期"
        end-placeholder="结束账期"
        style="width:220px"
        />
        <el-button size="small" type="primary" @click="downLoadUrl" v-if="this.from != '历史科目' && this.from != '历史凭证'">点击下载模板</el-button>
      </el-form-item>
      <el-form-item label="" v-if="this.mubanType == '2'">
        <el-date-picker v-if="this.from == '历史科目'" v-model="period" type="month" placeholder="请选择账期" value-format="YYYYMM" format="YYYYMM"></el-date-picker>
        <el-date-picker
        v-if="this.from == '历史凭证'"
        size="small"
        v-model="changeTime"
        type="monthrange"
        range-separator="-"
        value-format="YYYYMM"
        format="YYYYMM"
        start-placeholder="开始账期"
        end-placeholder="结束账期"
        style="width:220px"
        />
        <el-button size="small" type="primary" @click="downLoadUrl" v-if="this.from != '历史科目' && this.from != '历史凭证'">点击下载模板</el-button>
      </el-form-item>
      <el-form-item label="" v-if="this.from == '历史科目' || this.from == '历史凭证'">
        <!-- <a :href="this.excelUrl">点击</a> -->
        <el-button size="small" type="primary" @click="downLoadUrl">点击下载模板</el-button>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
        <el-button :loading="loading" type="primary" @click="uploadExcel()" size="small">确定</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog :close-on-click-modal="false"
    v-model="dialogVisible"
    title="提示"
    width="30%"
  >
    <el-table :data="gridData" height="500">
      <el-table-column label="提示信息" align="center" min-width="120">
        <template #default="scope">
          <span><span style="color:red">{{ scope.row }}</span></span>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" size="small" @click="dialogVisible = false">
          关闭
        </el-button>
      </span>
    </template>
  </el-dialog>
   
  <otherBank ref="bankTy"></otherBank>
</template>


<script>

import { importTemplate, inImport, outImport , shougouImport ,importExcel ,subjectImport ,empImport , salaryImport ,salaryInitImport ,bankImport , oldSubjectImport , oldVoucherImport , importInventory , assetImport , fzhsImport, voucherImport ,billImport , feeImport ,subjectFzhsImport ,empSpeImport} from "@/api/import"


import otherBank from "./son/otherBank.vue"
import { time5toTime } from "@/utils"

export default {
  props: {
    hideButton: {
      type: Boolean,
      default: false
    },
    from: {
      type: String,
      default: ""
    },
    id:{
      type:Number,
      default:0
    },
    comId:{
      type:Number,
      default:0
    },
    liPeriod:{
      type:String,
      default:''
    }
  },
  components: {
    otherBank
  },
  data () {
    return {
      loading: false,
      dialogFormVisible: false,
      rawFile: '',
      path: '',
      list: [],
      type: '',
      mubanType:'2',
      period:'',
      beginTime:'',
      endTime:'',
      changeTime:'',
      excelUrl:'',
      gridData:[],
      dialogVisible:false
    }
  },
  created () {
    
  },
  mounted(){
    if(this.from != '银行' && this.from != '科目' && this.from != '历史科目' && this.from != '历史凭证'){
      this.mubanType = '1'
    }
  },
  methods: {
    uploadExcel(){
      // console.log(this.type,this.mubanType);
      if(this.type == "" && this.mubanType == '1'){
        this.$qzfMessage("请选择模板", 1)
        return
      }
      if(this.rawFile == ''){
        this.$qzfMessage("请上传文件", 1)
        return
      }
      const param = new FormData() // 创建form对象
      param.append('file', this.rawFile, this.rawFile.name) // 单个图片 ，多个用循环 append 添加
      param.append('type', this.type)
      param.append('from', this.from)
      // console.log(this.rawFile);
      if(this.comId){
        param.append('comId', this.comId)
      }
      if(this.id){
        param.append('id', this.id)
      }
      if(this.period){
        param.append('period', this.period)
      }
      if(this.liPeriod){
        param.append('period', this.liPeriod)
      }
      if(this.from == "进项"){
        this.loading = true
        inImport(param).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.success()
            if(res.data.data && res.data.data.log){
              this.$qzfMessage(res.data.data.log)
              return
            }
            this.$qzfMessage("导入成功")
            if(res.data.data && res.data.data.list && res.data.data.list.length != 0){
              this.dialogVisible = true
              this.gridData = res.data.data.list
            }
          }
        })
      }else if(this.from == "销项"){
        this.loading = true
        outImport(param).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("导入成功")
            this.success()
          }
        })
      }else if(this.from == "收购"){
        this.loading = true
        shougouImport(param).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("导入成功")
            this.success()
          }
        })
      }else if(this.from == "科目"){
        this.loading = true
        if(this.mubanType == '2'){
          importExcel(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              // console.log(res.data.data)
              this.$emit('success2',res.data.data.data[0].data)
              this.dialogFormVisible = false
            }
          })
        }else{
          subjectImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
            }
          })
        }
      }else if(this.from == "员工"){
        this.loading = true
        empImport(param).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("导入成功")
            this.success()
          }
        })
      }else if(this.from == "薪酬"){
        param.append('sheetId',this.$store.getters["commons/params"].salaryNormalId)
        this.loading = true
        salaryImport(param).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("导入成功")
            this.success()
          }
        })
      }else if(this.from == "税款计算"){
        this.loading = true
        salaryInitImport(param).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("导入成功")
            this.success()
          }
        })
      }else if(this.from == "银行"){
        this.loading = true
        if(this.mubanType == '2'){
          importExcel(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              // res.data.data.data[0].data.map(v => {
              //   v[0] = time5toTime(v[0]);
              // })
              let bankList = res.data.data.data[0].data
              let max = 0
              bankList.map(v => {
                if(v.length > max){
                  max = v.length
                }
              })
              bankList.map(v=>{
                for(let i=0;i<max;i++){
                  v.push("")
                }
              })
              this.$emit('success2',bankList)
              this.dialogFormVisible = false
            }
          })
        }else{
          bankImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
            }
          })
        }
      }else if(this.from == "历史科目"){
        if(!this.period){
          this.$qzfMessage("请选择账期",1)
          return
        }
        this.loading = true
        if(this.mubanType == '2'){
          importExcel(param).then(res=>{
            this.loading = false
            
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              let params = {
                item:res.data.data.data[0].data,
                comId:this.comId,
                period:this.period
              }
              this.$emit('success2',params)
              this.dialogFormVisible = false
            }
          })
        }else{
          oldSubjectImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
            }
          })
        }
      }else if(this.from == "历史凭证"){
        this.beginTime = this.changeTime[0]
        this.endTime = this.changeTime[1]
        if(!this.changeTime){
          this.$qzfMessage("请选择账期区间",1)
          return
        }
        if(this.beginTime){
          param.append('beginTime', this.beginTime)
        }
        if(this.endTime){
          param.append('endTime', this.endTime)
        }
        this.loading = true
        if(this.mubanType == '2'){
          importExcel(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              let params = {
                item:res.data.data.data[0].data,
                comId:this.comId,
                beginTime:this.beginTime,
                endTime:this.endTime,
              }
              this.$emit('success2',params)
              this.dialogFormVisible = false
            }
          })
        }else{
          oldVoucherImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
            }
          })
        }
      }else if(this.from == "存货"){
        this.loading = true
        importInventory(param).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("导入成功")
            this.success()
            this.$store.dispatch("commons/getInventory")
            this.$store.dispatch("commons/getBusinessType")
          }
        })
      }else if(this.from == "固定资产" || this.from == "无形资产" || this.from == "费用摊销"){
        this.loading = true
        assetImport(param).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("导入成功")
            this.success()
          }
        })
      }else if(this.from == "辅助核算"){
        this.loading = true
        fzhsImport(param).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("导入成功")
            this.success()
          }
        })
      }else if(this.from == "凭证"){
        this.loading = true
        voucherImport(param).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("导入成功")
            this.success()
          }
        })
      }else if(this.from == "票据"){
        this.loading = true
        billImport(param).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("导入成功")
            this.success()
          }
        })
      }else if(this.from == "费用"){
        this.loading = true
        feeImport(param).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("导入成功")
            this.success()
          }
        })
      }else if(this.from == "会计科目"){
        this.loading = true
        subjectFzhsImport(param).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("导入成功")
            this.success()
          }
        })
      }else if(this.from == "专项扣除"){
        this.loading = true
        empSpeImport(param).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("导入成功")
            this.success()
          }
        })
      }
    },
    clickQt(e){
      // console.log(this.mubanType);
    },
    handImport(){
      importTemplate({source: this.from}).then(res=>{
        if(res.data.data.list){
          this.list = res.data.data.list
          this.type = this.list[0].type
          this.excelUrl = this.list[0].excelUrl
          if(res.data.data.memory != ""){
            this.list.map(v=>{
              if(v.type == res.data.data.memory){
                this.type = v.type
                this.excelUrl = v.excelUrl
              }
            })
          }
        } else {
          this.list = []
        }
      })
      this.dialogFormVisible = true
    },
    handleClick(e) {
      // console.log(e);
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile

    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    success(e){
      this.dialogFormVisible = false
      this.$emit('success')
    },
    mubanChange(e){
      this.list.map(v=>{
        if(e == v.type){
          this.excelUrl = v.excelUrl
        }
      })
    },
    downLoadUrl(){
      if(this.excelUrl) {
        window.open(this.excelUrl)
      }else {
        this.$qzfMessage("请联系客服处理！",1)
      }
    }
  }
}
</script>
