<template>
  <div class="wrap">
    <el-select v-model="quarter" placeholder="请选择季度" size="small" @change="quarterChange">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>
<script>
  export default {
    data(){
      return{
        options: [
          {
            value: '1',
            label: '第1季度',
          },
          {
            value: '2',
            label: '第2季度',
          },
          {
            value: '3',
            label: '第3季度',
          },
          {
            value: '4',
            label: '第4季度',
          },
        ],
        quarter: '',
      }
    },
    methods:{
      quarterChange(){
        this.$emit('update:quarter', this.quarter)
      }
    },
    created() {
    }
  }
</script>
<style scoped>
.wrap{
  float: left;
  margin-left: 5px;
}
</style>