<template>
  <el-input :type="type" :size="size" @blur="confirm" v-model="num"></el-input>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    num: {
      type: Number,
      default: 0,
    },
    size:{
      type:String,
      default:'small'
    }
  },
  data () {
    return {
      status: true,
    };
  },
  methods: {
    confirm(){
      // alert(String(this.num).split("")[String(this.num).split("").length - 1])
      if(this.num.indexOf('.0') != -1 && String(this.num).split("")[String(this.num).split("").length - 1] == 0){
        return
      }
      if( isNaN(Number(this.num))){
        this.$emit('update:num', 1)
        this.$emit('update:num', 0)
        return
      }
      this.$emit('update:num', Number(this.num))

      this.$emit('change', Number(this.num))
    },
  }
};
</script>
<style lang="scss" scoped>

</style>


