import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
// import Home from '../views/Home.vue'
const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import('../views/About.vue')
  // }
  
  {
    path: '/login',
    name: 'login',
    meta: { noCache: true },
    component: () => import('@/views/login/login.vue')
  },
  { 
    path: '/404', 
    name: 'err404',
    component: () => import ('@/views/redirect/404'),
    meta: { noCache: true },
    hidden: true,
  },
  
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
})

export default router
