import request from '@/utils/request'

//减免性质代码
export function gsJmxzdm(data) {
  return request({
    url: 'api/v2/taxCalculationApi/gsJmxzdm',
    method: 'post',
    data
  })
}
// 保存税种信息
export function saveTaxCategory(data) {
  return request({
    url: 'api/v2/taxCalculationApi/saveTaxCategory',
    method: 'post',
    data
  })
}
// 税种信息列表
export function taxCategoryList(data) {
  return request({
    url: 'api/v2/taxCalculationApi/taxCategoryList',
    method: 'post',
    data
  })
}
// 税种信息删除
export function delOneTaxCategory(data) {
  return request({
    url: 'api/v2/taxCalculationApi/delOneTaxCategory',
    method: 'post',
    data
  })
}

// 会计报表 生成
export function createKj(data) {
  return request({
    url: 'api/v2/taxCalculationApi/createKj',
    method: 'post',
    data
  })
}

// 会计报表 info
export function gsInfo(data) {
  return request({
    url: 'api/v2/taxCalculationApi/gsInfo',
    method: 'post',
    data
  })
}

// 会计报表 保存
export function gsEdit(data) {
  return request({
    url: 'api/v2/taxCalculationApi/gsEdit',
    method: 'post',
    data
  })
}

//税务报表
export function eaTaxCategoryList(data) {
  return request({
    url: 'api/v2/taxCalculationApi/eaTaxCategoryList',
    method: 'post',
    data
  })
}
//计提科目模板
export function eaTaxCategorySet(data) {
  return request({
    url: 'api/v2/taxCalculationApi/eaTaxCategorySet',
    method: 'post',
    data
  })
}

//税务报表 list
export function taxList(data) {
  return request({
    url: 'api/v2/taxCalculationApi/taxList',
    method: 'post',
    data
  })
}

//税务报表 取数
export function taxCreate(data) {
  return request({
    url: 'api/v2/taxCalculationApi/taxCreate',
    method: 'post',
    data
  })
}

//企业所得税以前年度亏损
export function gsOtherInfo(data) {
  return request({
    url: 'api/v2/taxCalculationApi/gsOtherInfo',
    method: 'post',
    data
  })
}

//企业所得税以前年度亏损 保存
export function gsOtherSave(data) {
  return request({
    url: 'api/v2/taxCalculationApi/gsOtherSave',
    method: 'post',
    data
  })
}

//初始化报表 list
export function getInitTax(data) {
  return request({
    url: 'api/v2/taxCalculationApi/getInitTax',
    method: 'post',
    data
  })
}

//申报详情列表
export function taxTaskList(data) {
	return request({
		url: 'api/v2/taxCalculationApi/taxTaskList',
		method: 'post',
		data
	})
}

//利润表季报取数来源
export function eaLrbOriginLists(data) {
	return request({
		url: 'api/v2/taxCalculationApi/eaLrbOriginLists',
		method: 'post',
		data
	})
}
//利润表季报取数来源保存
export function eaLrbOriginSave(data) {
	return request({
		url: 'api/v2/taxCalculationApi/eaLrbOriginSave',
		method: 'post',
		data
	})
}


export function initTaxJt(data) {
	return request({
		url: 'api/v2/taxCalculationApi/initTaxJt',
		method: 'post',
		data
	})
}
export function zeroDeclaration(data) {
	return request({
		url: 'api/v2/taxCalculationApi/zeroDeclaration',
		method: 'post',
		data
	})
}

export function taxCategorySbzqEdit(data) {
	return request({
		url: 'api/v2/taxCalculationApi/taxCategorySbzqEdit',
		method: 'post',
		data
	})
}