import { findSubjects } from '@/api/subject'
import { getPeriods } from '@/api/company'
import { taxCategoryList, invoiceKpxm, corps } from '@/api/invoice'
import { businessType } from '@/api/base'


export const commons = {
  namespaced: true,
  state: {
    params:{},//参数
    periods: [],//账期列表
    subjects: [],//公司所有科目
    subjectsLower: [],//公司最下级科目
    inventorys: {},//存货服务相关数据（销项）
    corps:{},//往来单位相关信息
    taxCategorys: [],//税目
    businessType: {},//基础配置数据
  },
  mutations: {
    setPeriods(state, periods) {
      state.periods = periods
    },
    SET_PARAM: (state, data) => {
      state.params = data
    },
    GET_SUBJECTS: (state, list) => {
      state.subjects = list
    },
    GET_SUBJECTS_LOWER: (state, list) => {
      state.subjectsLower = list
    },
    SET_INVENTORYS: (state, data) => {
      state.inventorys = data
    },
    SET_CORPS: (state, data) => {
      state.corps = data
    },
    SET_TAX_CATEGORYS: (state, data) => {
      state.taxCategorys = data
    },
    SET_BusinessType: (state, data) => {
      state.businessType = data
    },
  },
  actions: {
    InitPeriod({ commit },e) {
      return new Promise((resolve, reject) => {
        let periods = []
        getPeriods({comId: e}).then(res=>{
          let thisPeriod = res.data.data.listThis
          let oldPeriod = res.data.data.listOld
          if(thisPeriod){
            periods.push({
                label: '正常账期',
                options: thisPeriod,
            })
          }
          if(oldPeriod){
            periods.push({
                label: '历史账期',
                options: oldPeriod,
            })
          }
          commit('setPeriods', periods)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    setParam({ commit }, data) {
      data = Object.assign(commons.state.params, data);
      commit('SET_PARAM', data)
    },
    getSubjects({ commit }) {//基础科目
      return new Promise((resolve, reject) => {
        findSubjects({ code: []}).then(response => {
          let listAll = response.data.data.list
          let listlow = response.data.data.listLow
          commit('GET_SUBJECTS_LOWER', listlow)
          commit('GET_SUBJECTS', listAll)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    getInventory({ commit }) {//基础存货
      return new Promise((resolve, reject) => {
        invoiceKpxm({}).then(response => {
          let data = response.data.data
          commit('SET_INVENTORYS', data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    getTaxCategoryList({ commit }) {//基础税目
      return new Promise((resolve, reject) => {
        taxCategoryList({}).then(response => {
          let data = response.data.data.list
          commit('SET_TAX_CATEGORYS', data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    getCorp({ commit }) {//基础往来单位
      return new Promise((resolve, reject) => {
        corps({}).then(response => {
          let data = response.data.data
          commit('SET_CORPS', data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    getBusinessType({ commit }){
      return new Promise((resolve, reject) => {
        businessType({}).then(response => {
          let data = response.data.data
          data.in.map(v=>{
            let items = v.items?v.items:[]
            if(v.dataType == 'inventory'){
              items.unshift({
                sign: "addInventory",
                name: '+新增存货',
              })
            }else{
              items.unshift({
                sign: "addSubject",
                name: '+新增科目',
              })
            }
            if(v.name == '固定资产' || v.name == '无形资产' || v.name == '待摊费用'){
              items.unshift({
                sign: "addAssage",
                name: '+关联资产',
              })
            }
            v.items = items
          })

          data.bank.map(v=>{
            let items = v.items?v.items:[]
            items.unshift({
              sign: "addSubject",
              name: '+新增科目',
            })
            v.items = items
          })
          data.cash.map(v=>{
            let items = v.items?v.items:[]
            items.unshift({
              sign: "addSubject",
              name: '+新增科目',
            })
            v.items = items
          })
          data.bill.map(v=>{
            let items = v.items?v.items:[]
            items.unshift({
              sign: "addSubject",
              name: '+新增科目',
            })
            v.items = items
          })

          commit('SET_BusinessType', data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  getters: {
    periods(state) {
      return state.periods
    },
    params(state) {
      return state.params
    },
    subjects(state) {
      return state.subjects
    },
    subjectsLower(state) {
      return state.subjectsLower
    },
    inventorys(state) {
      return state.inventorys
    },
    corps(state) {
      return state.corps
    },
    taxCategorys(state) {
      return state.taxCategorys
    },
    businessType(state) {
      return state.businessType
    }
  }
}
//初始化账期
// store.dispatch('commons/InitPeriod', param.comId)


//跳转页面 参数传递
// this.$store.dispatch("commons/setParam", {addCustomerComId: e})
// this.$router.push({
//   path: "/accounting-system/subject_balance_uploadExcel_new",  
//   name: "subject_balance_uploadExcel_new",
// });

//let id = this.$store.getters["commons/params"].addCustomerComId


//获取科目
// this.$store.dispatch("commons/getSubjects")//更新科目 重新获取
//this.$store.getters.subjects
//this.$subjectFilterCode(this.code?this.code.split(","):[], 1)//1代表只要最下级
