<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="生成凭证" v-model="dialogVisible" width="60%" > 
    <!-- 凭证 -->
    <div class="big_box3">
      <!-- 顶部标题 -->
      <div class="top_title clearfix">
        <h5 style="position:relative">记账凭证
          <span style="position:absolute;right:5px;font-size:14px">
            <!-- {{1}} -->
          </span>
        </h5>
        <div class="bottom_time clearfix">
          <div class="top_input3 clearfix fl">
            <h6>记</h6>
            <el-input size="small" v-model="voucherNo" style="width:65px" :disabled=has></el-input>
            <span>号</span>
          </div>
        </div>
      </div>
      <!-- 顶部标题 end -->
      <div class="content2 clearfix">
        <div class="tops">
          <span class="box_top">
            <h6>摘要</h6>
          </span>
          <span class="box_top">
            <h6>会计科目</h6>
          </span>
          <span class="box_top">
            <h6>借方金额</h6>
            <!-- <div class="gsbqw">
              <div>百</div>
              <div>十</div>
              <div>亿</div>
              <div>千</div>
              <div>百</div>
              <div>十</div>
              <div>万</div>
              <div>仟</div>
              <div>佰</div>
              <div>什</div>
              <div>元</div>
              <div>角</div>
              <div>分</div>
            </div> -->
          </span>
          <span class="box_top">
            <h6>贷方金额</h6>
            <!-- <div class="gsbqw">
              <div>百</div>
              <div>十</div>
              <div>亿</div>
              <div>千</div>
              <div>百</div>
              <div>十</div>
              <div>万</div>
              <div>仟</div>
              <div>佰</div>
              <div>什</div>
              <div>元</div>
              <div>角</div>
              <div>分</div>
            </div> -->
          </span>

        </div>
        <!-- for 循环 -->
        <div class="bottom clearifx" v-for="(item,index) in list" :key="index" style="overflow:hidden">
          <div class="common_box">
            <p>{{item.summary}}</p>
          </div>
          <div class="common_box">
            <h6 class="infarm_box">{{item.subjectName}}</h6>
          </div>
          <div class="text_big common_box">
            <ul class="text_box clearfix">
              <li>{{item.inAmount}}</li>
            </ul>
          </div>

          <div class="text_big common_box">
            <ul class="text_box clearfix">
              <li>{{item.outAmount}}</li>
            </ul>
          </div>
        </div>

        <div class="bottom clearifx">
          <div class="common_box" style="width:50%">
            <p>合计</p>
          </div>

          <div class="text_big common_box">
            <ul class="text_box clearfix">
              <li>{{inSumAmount}}</li>
            </ul>
          </div>

          <div class="text_big common_box">
            <ul class="text_box clearfix">
              <li>{{outSumAmount}}</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 底部信息 -->
      <div class="bottom_input clearfix">
        <div class="left_box fl">
          <div class="item_btn fl clearfix">
            <h6>制作人：</h6>
            <p style="width:50%;">{{info.userName}}</p>
          </div>
          <div class="item_btn fl clearfix" style="margin-left:15px;">
            <h6>制作时间：</h6>
            <!-- <p style="width:50%;">{{info.createdAt| parseTime('{y}-{m}-{d}')}}</p> -->
          </div>
        </div>
        <div class="right_box fr">
          <div class="item_btn fl clearfix">
            <h6>审核人：</h6>
            <p style="width:50%;">{{info.userName}}</p>
          </div>
          <div class="item_btn fl clearfix" style="margin-left:15px;">
            <h6>审核时间：</h6>
            <p style="width:50%;"></p>
          </div>
        </div>
      </div>
      <!-- 底部信息 end -->
    </div>
    <!-- 凭证 end -->
    <!-- <div class="bottom_btn" v-if="!has">
      <el-button type="primary" @click="dialogVisible = false" size="small">取消</el-button>
      <el-button type="primary" @click="handleSave" size="small">保存</el-button>
    </div> -->
  <template #footer v-if="!has">
    <span class="dialog-footer">
      <el-button type="primary" @click="dialogVisible = false" size="small">取消</el-button>
      <el-button type="primary" @click="handleSave" size="small">保存</el-button>
    </span>
  </template>
</el-dialog>
</template>

<script>
import { voucherPreview , saveVoucher } from '@/api/voucher.js'

function converStr(str) {
  //给数字字符串添加逗号分隔符
  if (/\./.test(str)) {
    return str
      .replace(/\d(?=(\d{1})+\.)/g, "$&,")
      .split("")
      .reverse()
      .join("")
      .replace(/\d(?=(\d{1})+\.)/g, "$&,")
      .split("")
      .reverse()
      .join("");
  } else {
    return str.replace(/\d(?=(\d{1})+$)/g, "$&,");
  }
}

export default {
  name:'voucherShow',
  props:{
    from:{   
      type:String,    
      // 进项 "1", 销项 "2", 费用 "3" , 现金 "4" , 票据 "5", 银行 "6"
      default:''
    },
    id:{
      type:Number,
      default:0
    },
    voucherId:{
      type:Number,
      default:0
    }
  },
  data() {
    return {
      dialogVisible:false,
      has: false, // 已经生成凭证
      info: {}, //外层的信息
      list: [],
      outSumAmount:"",
      inSumAmount:''
      // noAmount: ["", "", "", "", "", "", "", "", "", "", "", "", ""], // 默认的数组金额
      // sumTotalArray: ["", "", "", "", "", "", "", "", "", "", "", "", ""], //合计的数组金额
      // inSumAmountArray: [],
      // outSumAmountArray: [],
    }
  },
  methods:{
    getList(item){
      // console.log(item);
      let param = {
        id:item.id,
        from:this.from,
        voucherId:item.voucherId
      }
      voucherPreview(param).then(res=>{
        if(res.data.msg == "success"){
          this.dialogVisible = true
          this.info = res.data.data.info
          this.inSumAmount = this.info.inSumAmount
          this.outSumAmount = this.info.outSumAmount
          if(res.data.data.info.voucherItem){
          // res.data.data.info.voucherItem.map(item=>{
          //   item.inAmount = this.noAmount;//初始化
          //   item.outAmount = this.noAmount;//初始化 都是空的  后面如果有数值就给赋上

          //   let amountArray = converStr((item.outAmount * 100).toFixed(0)).split(
          //     ","
          //   ); // 如果是21 ，转换成[2,1,0,0]  要补齐13位，前面都补上空字符串
          //   let dif = 13 - amountArray.length;
          //   for (let i = 0; i < dif; i++) {
          //     amountArray.unshift(" ");
          //   }
          //   if (item.type === 1) {
          //     item.inAmount = amountArray;
          //   } else {
          //     item.outAmount = amountArray;
          //   }
          // })
          this.list = res.data.data.info.voucherItem
        }else{
          this.list = []
        }
        }
        // let inSumAmountArray = converStr(
        //   (this.info.inSumAmount * 100).toFixed(0)
        // ).split(","); //ssumtotal数组
        // let outSumAmountArray = converStr(
        //   (this.info.outSumAmount * 100).toFixed(0)
        // ).split(","); //ssumtotal数组

        // let dif = 13 - inSumAmountArray.length;
        // for (let i = 0; i < dif; i++) {
        //   inSumAmountArray.unshift(" ");
        //   outSumAmountArray.unshift(" ");
        // }
        // this.inSumAmountArray = inSumAmountArray;
        // this.outSumAmountArray = outSumAmountArray;
      })
    },
    handleSave(){
      saveVoucher(this.info).then(res=>{
        if(res.data.msg == "success"){
          this.dialogVisible = false
          this.$emit('success')
          this.$qzfMessage("生成成功")
          this.$bus.emit('voucherUpdate')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1280px) and (max-width: 1366px) {
  .big_box3 {
    width: 1000px !important;
  }
}
.big_box3 {
  width: 100%;
  padding: 40px;
  background: #fff;
  box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  border:1px solid #ddd;
  .bottom_time {
    float: left;
    width: 40%;
  }
  .top_input2 {
    width: 50%;
    h6 {
      display: inline-block;
      width: 40px;
      font-size: 14px;
      font-weight: normal;
    }
  }
  .top_input3 {
    width: 50%;
    h6 {
      float: left;
      font-size: 15px;
      color: #333;
      font-weight: normal;
      line-height: 36px;
      margin-right: 10px;
    }
    span {
      font-size: 15px;
      color: #333;
      font-weight: normal;
      line-height: 36px;
      margin-left: 10px;
    }
    input {
      float: left;
      width: 47%;
      border: none;
      border-bottom: 1px solid #666;
      margin-left: 5px;
      line-height: 22px;
    }
  }
}
.top_title {
  width: 100%;
  margin: 0 auto;
  h5 {
    font-size: 30px;
    color: #333;
    text-align: center;
    font-weight: 500;
    margin-bottom: 36px;
  }
}
.content2 {
  width: 100%;
  margin: 0 auto;
  border: 3px solid #999;
  margin-top: 10px;
  position: relative;
}
.tops {
  font-size: 0;
  position: relative;
  display: flex;
  .add {
    position: absolute;
    right: -38px;
    top: 15px;
    width: 1.8rem;
    height: 1.8rem;
    vertical-align: -0.15rem;
    fill: currentColor;
    overflow: hidden;
    color: #f15a24;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
  .gsbqw {
    font-size: 0;
    border-top: 1px solid #f3f3f3;
    display: flex;
    div {
      float: left;
      width: calc(100% / 8.8);
      height: 28px;
      font-size: 14px;
      vertical-align: top;
      border-left: 1px solid #f3f3f3;
    }
    div:nth-child(3) {
      border-color: #FFA9A9;
    }
    div:nth-child(6) {
      border-color: #FFD089;
    }
    div:nth-child(9) {
      border-color: #81ff89;
    }
    div:nth-child(12) {
      border-color: #9dd7ff;
    }
    div:nth-child(1) {
      border-left: none;
    }
  }
  .box_top {
    width: 25%;
    border-left: 1px solid #666;
    border-bottom: 1px solid #666;
    display: inline-block;
    height: 60px;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 15px;
    vertical-align: top;
    line-height: 60px;
    box-sizing: border-box;
    h6 {
      font-weight: 600;
      font-size: 17px;
      color: #333;
    }
    > div {
      width: 100%;
      height: 30px;
      line-height: 30px;
      font-weight: 500;
    }
  }
  .box_top:nth-child(1) {
    border-left: none;
  }
}
.bottom {
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid #666;
  height: 82px;
  position: relative;
  p {
    font-size: 14px;
    text-align: center;
    line-height: 82px;
    font-weight: normal;
  }
  .add {
    position: absolute;
    right: -38px;
    top: 8px;
    width: 1.8em;
    height: 1.8em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    color: #f15a24;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
  .close {
    width: 1.8em;
    height: 1.8em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    right: -38px;
    top: 24px;
    font-size: 16px;
    color: #ff1818;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.common_box {
  float: left;
  // min-width: 2px;;
  height: 100%;
  width: 25%;
  border-left: 1px solid #666;
}
.common_box:first-child {
  border-left: 0;
}
.infarm_box {
  // resize: none;
  width: 100%;
  height: 82px;
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
  box-sizing: border-box;
  color: #333;
  border: none;
  margin: 0;
  padding: 5px;
}
.text_big {
  position: relative;
  height: 82px;
  li {   //样式todo
    float: left;
    // width: calc(100% / 8.8);
    height: 82px;
    line-height: 82px;
    text-align: center;
    font-size: 14px;
    color: #333;
    vertical-align: top;
    border-left: 1px solid #f3f3f3;
    z-index: 8;
    position: relative;
    font-weight: bold;
  }
  li:nth-child(1) {
    border-left: none;
  }
  input {
    width: 100%;
    height: 100%;
    background: #fff;
    margin: 0 auto;
    z-index: 9999;
    box-shadow: 0 2px 10px #ddd;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.text_box li:nth-child(3) {
  border-color: #f0ffb6;
}
.text_box li:nth-child(6) {
  border-color: #ffedb0;
}
.text_box li:nth-child(9) {
  border-color: #81ff89;
}
.text_box li:nth-child(12) {
  border-color: #9dd7ff;
}
.text_box li {
  padding: 3px;
}
.bottom_input {
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  .item_btn {
    width: 46%;
    margin: 0 auto;
    margin-bottom: 15px;
    position: relative;
    h6 {
      float: left;
      width: 80px;
      font-size: 15px;
      color: #333;
      font-weight: normal;
      line-height: 36px;
      margin-right: 10px;
      text-align: right;
    }
    p {
      float: left;
      line-height: 36px;
      color: #333;
      font-size: 15px;
      font-weight: normal;
    }
    input {
      float: left;
      width: 47%;
      border: none;
      border-bottom: 1px solid #666;
      margin-left: 5px;
      line-height: 22px;
    }
  }
  .left_box {
    width: 46%;
  }
  .right_box {
    width: 46%;
    // text-align: right;
  }
}
.bottom_btn {
  width: 1100px;
  margin: 0 auto;
  margin-top: 30px;
  text-align: right;
}
</style>