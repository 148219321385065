import axios from 'axios'
import { ElMessage } from 'element-plus'
import store from '@/store'
import { getToken, removeToken } from '@/utils/auth'
import { Decrypt } from '@/utils/aes'

// create an axios instance
const service = axios.create({
  baseURL: process.env.BASE_API, // api 的 base_url
  timeout: 600000 // request timeout
})
let loadingInstance;


service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['Token'] = getToken()
    }
    config.headers['ComId'] = store.getters['user/comInfo'].comId * 1
    config.headers['Period'] = store.getters['user/comInfo'].period
    config.headers['t'] = store.getters['user/comInfo'].t

    // if(config.data){
    //   if(!config.data.comId){
    //     config.data.comId = store.getters['user/comInfo'].comId * 1
    //   }
    //   if(!config.data.period){
    //     config.data.period = store.getters['user/comInfo'].period
    //   }
    // };
    return config
  },
  response => { 
    console.log("返回23：" + response)
  },
  error => {
    console.log(error) // for debug
    Promise.reject(error)
  }
  
)

service.interceptors.response.use(
  response => {
    if(response.data){
      if(response.data.errNo > 0){
        // if(response.data.msg == "未登录" && process.env.NODE_ENV === 'production'){
        if(response.data.msg == "未登录"){
          // window.location.href = "https://qizhangfang.com"
          removeToken()
          location.reload()
        }
        ElMessage({
          message: response.data.msg,
          type: 'error',
          duration: 5 * 1000
        })
      }else{
        if(response.data.jsonType === 1){
          let aesData = response.data.data
          let data = Decrypt(aesData)
          response.data.data = JSON.parse(data)
        }
        return response
      }
    }
    return response
  },
  error => {
    console.log('err' + error) // for debug
    ElMessage({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
