import request from '@/utils/request'


// 集团
export function saveGroup(data) {
  return request({
    url: 'api/v2/org/saveGroup',
    method: 'post',
    data
  })
}
//集团
export function groupList(data) {
  return request({
    url: 'api/v2/org/groupList',
    method: 'post',
    data
  })
}
// 企业列表
export function orgList(data) {
  return request({
    url: 'api/v2/org/orgList',
    method: 'post',
    data
  })
}

// 新增修改企业
export function saveOrg(data) {
  return request({
    url: 'api/v2/org/saveOrg',
    method: 'post',
    data
  })
}

// 删除企业
export function delOrg(data) {
  return request({
    url: 'api/v2/org/delOrg',
    method: 'post',
    data
  })
}

// 企业管理员
export function findOrgAdmin(data) {
  return request({
    url: 'api/v2/org/findOrgAdmin',
    method: 'post',
    data
  })
}

// 企业管理员新增修改
export function saveOrgAdmin(data) {
  return request({
    url: 'api/v2/org/saveOrgAdmin',
    method: 'post',
    data
  })
}

export function updateList(data) {
  return request({
      url: '/api/v2/org/updateList',
      method: 'post',
      data
  })
}
// 更新提示详情
export function updateListOne(data) {
  return request({
      url: '/api/v2/org/updateListOne',
      method: 'post',
      data
  })
}

export function updateSave(data) {
  return request({
      url: '/api/v2/org/updateSave',
      method: 'post',
      data
  })
}
export function updateDel(data) {
  return request({
      url: '/api/v2/org/updateDel',
      method: 'post',
      data
  })
}
export function updateUserSave(data) {
  return request({
      url: '/api/v2/org/updateUserSave',
      method: 'post',
      data
  })
}

export function updateRead(data) {
  return request({
      url: '/api/v2/org/updateRead',
      method: 'post',
      data
  })
}

//锁
export function getLockList(data) {
  return request({
      url: '/api/v2/org/getLockList',
      method: 'post',
      data
  })
}

//锁删除
export function delLock(data) {
  return request({
      url: '/api/v2/org/delLock',
      method: 'post',
      data
  })
}

//
export function systemUpdate(data) {
  return request({
      url: '/api/v2/org/systemUpdate',
      method: 'post',
      data
  })
}

//
export function systemUpdateInfo(data) {
  return request({
      url: '/api/v2/org/systemUpdateInfo',
      method: 'post',
      data
  })
}

// 申报设置info
export function orgSettingInfo(data) {
  return request({
      url: '/api/v2/org/orgSettingInfo',
      method: 'post',
      data
  })
}

// 申报设置保存
export function orgSettingSave(data) {
  return request({
      url: '/api/v2/org/orgSettingSave',
      method: 'post',
      data
  })
}
// 平台集团账号管理
export function getGroupAdmin(data) {
  return request({
      url: '/api/v2/org/getGroupAdmin',
      method: 'post',
      data
  })
}
// 平台集团账号管理保存
export function saveGroupAdmin(data) {
  return request({
      url: '/api/v2/org/saveGroupAdmin',
      method: 'post',
      data
  })
}
//集团账号管理删除
export function delGroup(data) {
  return request({
      url: '/api/v2/org/delGroup',
      method: 'post',
      data
  })
}

//下载数据包list
export function getAuthOrgComList(data) {
  return request({
      url: '/api/v2/org/getAuthOrgComList',
      method: 'post',
      data
  })
}

//迁移
export function authCompanyOrgMove(data) {
  return request({
      url: '/api/v2/move/authCompanyOrgMove',
      method: 'post',
      data
  })
}