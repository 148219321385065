<template>
  <el-select @focus="selectFocus" :style="width != '0' ?{ width: width +'px' }: { width: '100%' }" :class="{boxShadowNone:borderNone}" :disabled="disabledIn" filterable v-model="id" :placeholder="placeholder" size="small" :clearable="addNotShow">
    <el-option v-if="!addNotShow" style="color:red" label="新增+" value="add"></el-option>
    <el-option
      v-for="item in subjectListOldList"
      :key="item.sign"
      :label="item.name3"
      :value="item.sign"
    >
    <span style="float: left">{{ item.name3 }}</span>
    <span style="float: right;color: red;font-size: 14px;">{{item.fzhsItemName}}</span>

    </el-option>
  </el-select>
  <add-subject @success="addSuccess" ref="addSubject"></add-subject>
  <!-- <subject-list subjectName="测试科目" v-model:subjectId="listQuery.id" v-model:fzhsItemId="listQuery.id2" :codes="['1001','1002']"></subject-list> -->
</template>
<script>
import addSubject from './addSubject.vue'
export default {
  components: { addSubject },
  props:{
    width: {
      type: String,
      default: '200'
    },
    codes: {//默认下拉框的code
      type: Array,
      default: []
    },
    subjectId: {
      type: Number,
      default: 0
    },
    fzhsItemId: {
      type: Number,
      default: 0
    },
    subjectName: {//新增科目的名字
      type: String,
      default: "",
    },
    borderNone:{
      type:Boolean,
      default:false
    },
    addNotShow:{
      type:Boolean,
      default:false
    },
    disabledIn:{
      type:Boolean,
      default:false
    },
    placeholder: {//默认下拉框的code
      type: String,
      default: "选择科目"
    },
    status: {//默认下拉框的code
      type: String,
      default: ""
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    subjectListOldList: {
      get(){
        if(this.status != "" && this.ss != this.index && this.id != "0-0" && this.subjectListOldList && this.subjectListOldList.length != 0){
          return
        }
        if(this.status != "" && this.ss != this.index  && this.id == "0-0"){
          return
        }
        let list = this.$subjectFilterCode(this.codes, 1)
        let l = []
        if(this.ss == ""){
          l = list
        } else {
          if(this.ss == this.index){
            l = list
          } else {
            l = list.filter(v=>{return v.sign == this.id})
          }
        }
        return l
      }
    },
    ss: {
      get(){
        return this.status
      },
      set(val){
        this.$emit("update:status", val)
      }
    },
    id: {
      get() {
        if(!this.subjectId){
          return ""
        }
        return this.subjectId + "-" + this.fzhsItemId
      },
      set(val) {
        if(val == 'add'){
          let data = {
            id: undefined,
            name: this.subjectName,
            type: 0,
          }
          this.$refs.addSubject.init(data, this.codes)
          val = undefined
          return
        }
        this.$emit("update:subjectId", val.split('-')[0] * 1)
        this.$emit("update:fzhsItemId", val.split('-')[1] * 1)
        this.$emit("success", val)
      }
      
    }
  },
  data () {
    return {
      // id: this.ids,
      // id: this.subjectId?this.subjectId + "-" + this.fzhsItemId:""
    }
  },
  methods: {
    selectFocus(){
      if(this.status == ""){
        return
      }
      console.log(11)
      this.ss = String(this.index)
    },
    addSuccess(e){
      this.id = e.sign
      // this.changeCode()
    },
    // changeCode(){
    //   this.$emit("update:subjectId", this.id.split('-')[0] * 1)
    //   this.$emit("update:fzhsItemId", this.id.split('-')[1] * 1)
    //   this.$emit("success", this.id)
    // }
  }
}
</script>

<style scoped>
.el-select{
  margin-left: 0px;
}
</style>

<style lang="scss" scoped>
.boxShadowNone {
  :deep(.el-input__wrapper) {
    box-shadow: none;
    background: none;
  }
}
</style>