import request from '@/utils/request'

//账期列表
export function getPeriods(data) {
	return request({
		url: 'api/v2/company/getPeriods',
		method: 'post',
		data
	})
}

//公司列表
export function eaCompanyList(data) {
	return request({
		url: 'api/v2/company/eaCompanyList',
		method: 'post',
		data
	})
}

//新增客户
export function saveEaCompany(data) {
	return request({
		url: 'api/v2/company/saveEaCompany',
		method: 'post',
		data
	})
}

//其他设置
export function getComSetList(data) {
	return request({
		url: 'api/v2/company/getComSetList',
		method: 'post',
		data
	})
}

export function setComSet(data) {
	return request({
		url: 'api/v2/company/setComSet',
		method: 'post',
		data
	})
}

// 企业基本信息
export function eaCompanyInfo(data) {
	return request({
		url: 'api/v2/company/eaCompanyInfo',
		method: 'post',
		data
	})
}

// 分配公司
export function assignCustomers(data) {
	return request({
		url: 'api/v2/company/assignCustomers',
		method: 'post',
		data
	})
}

// 停报公司
export function changeStatus(data) {
	return request({
		url: 'api/v2/company/changeStatus',
		method: 'post',
		data
	})
}

//删除公司
export function delCompany(data) {
	return request({
		url: 'api/v2/company/delCompany',
		method: 'post',
		data
	})
}

// 个税初始化
export function companyInitTaxList(data) {
	return request({
		url: 'api/v2/company/companyInitTaxList',
		method: 'post',
		data
	})
}

// 个税初始化
export function companyInitTaxListNoPeriod(data) {
	return request({
		url: 'api/v2/company/companyInitTaxListNoPeriod',
		method: 'post',
		data
	})
}

export function companySendTaxList(data) {
	return request({
		url: 'api/v2/company/companySendTaxList',
		method: 'post',
		data
	})
}

//发票
export function companyInvoiceList(data) {
	return request({
		url: 'api/v2/company/companyInvoiceList',
		method: 'post',
		data
	})
}

//分配的人员
export function assignCustomersList(data) {
	return request({
		url: 'api/v2/company/assignCustomersList',
		method: 'post',
		data
	})
}

//科目设置批量保存
export function setComSets(data) {
	return request({
		url: 'api/v2/company/setComSets',
		method: 'post',
		data
	})
}

//小记Info
export function comMemoryInfo(data) {
	return request({
		url: 'api/v2/company/comMemoryInfo',
		method: 'post',
		data
	})
}

//小记保存
export function comMemorySave(data) {
	return request({
		url: 'api/v2/company/comMemorySave',
		method: 'post',
		data
	})
}

//建账采集数据采集
export function companyHisList(data) {
	return request({
		url: 'api/v2/company/companyHisList',
		method: 'post',
		data
	})
}


//历史数据采集
export function companyHisImportList(data) {
	return request({
		url: 'api/v2/company/companyHisImportList',
		method: 'post',
		data
	})
}

//验证码list
export function smsRecList(data) {
	return request({
		url: 'api/v2/company/smsRecList',
		method: 'post',
		data
	})
}

//到票状态
export function getDpStatus(data) {
	return request({
		url: 'api/v2/company/getDpStatus',
		method: 'post',
		data
	})
}

//到票状态保存
export function saveDpStatus(data) {
	return request({
		url: 'api/v2/company/saveDpStatus',
		method: 'post',
		data
	})
}
//审核
export function toExamine(data) {
	return request({
		url: 'api/v2/company/toExamine',
		method: 'post',
		data
	})
}
//账套迁移
export function company1To2List(data) {
	return request({
		url: 'api/v2/company/company1To2List',
		method: 'post',
		data
	})
}

//勾选认证
export function loginTaxInfo(data) {
	return request({
		url: 'api/v2/company/loginTaxInfo',
		method: 'post',
		data
	})
}

//编号排序
export function saveEaCompanySort(data) {
	return request({
		url: 'api/v2/company/saveEaCompanySort',
		method: 'post',
		data
	})
}

//address 地区选择
export function getAddress(data) {
	return request({
		url: 'api/v2/company/getAddress',
		method: 'post',
		data
	})
}

// 获取已绑定列表
export function getBindWxUser(data) {
	return request({
		url: 'api/v2/company/getBindWxUser',
		method: 'post',
		data
	})
}

// 查询微信用户
export function searchWxUser(data) {
	return request({
		url: 'api/v2/company/searchWxUser',
		method: 'post',
		data
	})
}

// 公司绑定微信用户
export function comBindUser(data) {
	return request({
		url: 'api/v2/company/comBindUser',
		method: 'post',
		data
	})
}

// 汇算清缴list
export function companyYearList(data) {
	return request({
		url: 'api/v2/company/companyYearList',
		method: 'post',
		data
	})
}

//财务统计表列表
export function cwtjList(data) {
	return request({
		url: 'api/v2/company/cwtjList',
		method: 'post',
		data
	})
}
//财务统计表更新数据
export function cwtjFresh(data) {
	return request({
		url: 'api/v2/company/cwtj',
		method: 'post',
		data
	})
}