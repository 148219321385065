//注册全局方法
import { ElMessage ,ElMessageBox } from 'element-plus'

import store from '@/store'

export function message(text,type){
  let types = 'success'
  if(type == 1){
    types = "warning"
  }else if(type == 2){
    ElMessage.error(text)
    return
  }
  ElMessage({
    message: text,
    type: types,
  })
};

export function alertMsg(text){
  ElMessageBox.alert(text, '提示', {
    confirmButtonText: '确认',
  })
};

// 队列
export function queueMessage(text){
  if (!text || text.length == 0){
    return
  }
  ElMessageBox.alert(
    `
    <div style="height:350px;overflow:auto">
      ${
        text.map(item => {
          return `<div>${item}已在队列中</div>`
        }).join('')
      }
      </div>
    `
    ,
    {
      dangerouslyUseHTMLString:true,
      confirmButtonText: '确认',
    }
  )
};

// 队列
export function cancelMsg(text){
  if (!text || text.length == 0){
    return
  }
  ElMessageBox.alert(
    `
    <div style="height:350px;overflow:auto">
      ${
        text.map(item => {
          return `<div>${item.name}-${item.taskName}-${item.errLog}</div>`
        }).join('')
      }
      </div>
    `
    ,
    {
      dangerouslyUseHTMLString:true,
      confirmButtonText: '确认',
    }
  )
};

//深copy
export function copy(obj){
  if(!obj){
    return
  }
  var newobj = obj.constructor === Array ? [] : {};
  if(typeof obj !== 'object'){
    return
  }
  for(var i in obj){
    newobj[i] = typeof obj[i] === 'object' ? this.$qzfCopy(obj[i]) : obj[i];
  }
  return newobj
};


export function checkStrInArray(arr, str){
  let s = arr.filter(v=>{return str == v})
  if(s.length != 0){
    return true
  }
  return false
}


export function getUrlParams2(url, name) { // 不传name返回所有值，否则返回对应值
  if (url.indexOf('?') == 1) { return false; }
  url = url.substr(1);
  url = url.split('&');
  var name = name || '';
  var nameres;
  // 获取全部参数及其值
  for(var i=0;i<url.length;i++) {
    var info = url[i].split('=');
    var obj = {};
    obj[info[0]] = decodeURI(info[1]);
    url[i] = obj;
  }
  // 如果传入一个参数名称，就匹配其值
  if (name) {
    for(var i=0;i<url.length;i++) {
      for (const key in url[i]) {
          if (key == name) {
              nameres = url[i][key];
          }
        }
    }
  } else {
    nameres = url;
  }
  // 返回结果
  return nameres;
}

export function getUrlParams(url, name) { // 不传name返回所有值，否则返回对应值
  let s = url.split('?')
  if(s.length < 2){
    return ""
  }
  let cs = s[1]
  let csArr = cs.split('&')
  let val = ''
  csArr.map(v=>{
    if(name == v.split('=')[0]){
      val = v.split('=')[1]
    }
  })
  return val
}


//过滤科目 1只要最下级
export function subjectFilterCode(codes, type) {
  //subjects
  let list = []
  if(type == 1){
    list = store.getters['commons/subjectsLower']
  }else{
    list = store.getters['commons/subjects']
  }
  if(codes.length == 0){
    return list
  }
  return list.filter(v => {
    if (codes.indexOf(v.code.substring(0, 4)) > -1) {
      return v
    }
  })
}

export function findCode(arr){
  let codes = store.getters['user/comInfo'].codes
  let list = arr.map(v=>{
    return codes[v]
  })
  return list
}
//原型链上增加方法
// String.prototype.moneyFilter = function(){
//   if(this.indexOf('(') > -1){
//     let amount = this.replace("(", "").replace(")","")
//     return Number((-amount.replace(/,/g, "").replace("¥", "").replace("å", "").replace("元", "").replace("￥", "")  * 1).toFixed(2))
//   }else{
//     return Number((this.replace(/,/g, "").replace("¥", "").replace("å", "").replace("元", "").replace("￥", "")  * 1).toFixed(2))
//   }
// }


export function buttonStatus(code) {
  if(store.getters['user/user'].sysButton.indexOf(code) > -1){
    return false;
  }else{
    return true;
  }
}


export function checkSettleStatus(){
  let comPeriod = {}
  store.getters['commons/periods'][0].options.map(v=>{
    if(store.getters['user/comInfo'].period == v.period){
      comPeriod = v
    }
  })
  if(comPeriod.settleStatus == 4){
    return '本期已结账，请先进行反结账'
  }
  return ""
}

//获取本账期最后一天
export function getPeriodTime(filterVal, jsonData) {
  let period = store.getters['user/comInfo'].period
  let ri = '31'
  if(period.slice(4,6) == 2){
    ri = '28'
  }else if(period.slice(4,6) == '04' || period.slice(4,6) == '06' || period.slice(4,6) == '09' || period.slice(4,6) == '11'){
    ri = '30'
  }

  let str = period.slice(0,4) + '-' + period.slice(4,6) + "-" + ri
  return new Date(str)
}

export function getCachedViews(name){
  return store.getters['tagsView/cachedViews'].filter(v=>{return v == name}).length != 0
}