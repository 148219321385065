<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <a class="muban" href="https://file.listensoft.net/data/down/invoiceExcel/导入商品模板.xlsx" >通用模板下载</a>
    <upload-excel-component :rawFile='rawFile' :on-success="handleSuccess" :before-upload="beforeUpload" />
  </div>
</template>

<script>
import UploadExcelComponent from "@/components/import/son/index.vue";
import { addGoods } from "@/api/ea";
export default {
  components: { UploadExcelComponent },
    props: {
    rawFile: ""
  },
  
  data() {
    return {
      fullscreenLoading:false
    };
  },
  methods: {
    beforeUpload(file) {
      if(!file){
        this.$message({
          message: "请选择文件",
          type: "warning"
        });
        return false;
      }
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "请不要上传大于1M的文件。",
        type: "warning"
      });
      return false;
    },
    handleSuccess({ results, header }) {
      // console.log(results)
      // console.log(header)
      this.fullscreenLoading = true
      let items = [];
      results.map(item => {
        // console.log(item);
        item.map(v=>{
          let obj = {};
          obj.short_code = v["*税收分类编码"].toString()
          obj.name = v["*商品名称"]
          obj.spec = v["规格型号"].toString()
          obj.unit = v["计量单位"].toString()
          obj.price = v["单价（元）"].toString()
          obj.sl = v["税率"].toString()
          obj.hsbz = v["含税标志"].toString()
          obj.mslx = v["免税类型"].toString()
          obj.yh_status = v["是否优惠政策"].toString()
          obj.yh_text = v["优惠政策类型"]
          obj.comId = this.$store.getters['user/comInfo'].id
          items.push(obj);
        })
      });
      // console.log(items)
      addGoods(items).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("导入成功", 3)
          this.fullscreenLoading = false
          this.$emit("success")
        }
      })
    }
  }
};
</script>


<style lang="scss" scoped>
.muban {
  text-align: left;
  font-size: 14px;
  color: red;
  display: block;
  width: 150px;
}
</style>
