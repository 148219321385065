<template>
  <div class="message_content" ref="message">
    <div class="message_list" v-if="showBig">
      <i @click="showBig = false" class="iconfont icon-cuowu11 iconClass"></i>
        <ul class="list_card" v-for="item in list" :key="item.id">
          <li>
            <div class="top_tag">
              <p>{{item.title}}</p>
            </div>
            <div class="message_main">
              <h5>{{item.name}}</h5>
              <h6>尊敬的用户您好：</h6>
              <div class="message_p" v-html="item.content">
              </div>
            </div>
            <div class="bottom_btns">
              <span>{{ $parseTime(item.createdAt, "{y}-{m}-{d}")}}</span>
              <p @click="seeDetails(item)">查看详情</p>
            </div>
          </li>
        </ul>
    </div>
    <div class="message_list" v-if="showDownload">
      <i @click="showDownload = false" class="iconfont icon-cuowu11 iconClass"></i>
      <ul class="load_list">
        <li v-for="item in downloadList" :key="item.id">
          <p v-html="item.content"></p>
        </li>
      </ul>
    </div>
    
    <div @click.stop="showBig = false" v-if="showBig" class="zz">
    </div>
    <div @click.stop="showDownload = false" v-if="showDownload" class="zz">
    </div>
    <!-- 查看详情弹框 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close :title="detail.title" v-model="dialogFormVisible" class="new_dialog button_bg" append-to-body :showClose=false width="40%">
      <el-form ref="dataForm">
        <div class="message_detail clearfix">
          <h6>{{detail.name}}:</h6>
          <div v-html="detail.content" class="contentStyle" @click="wrapClick($event,detail.content)"></div>
        </div>
      </el-form>
      <el-image-viewer
        @close="closeViewer"
        v-if="showViewer"
        :url-list="srcList"
      />
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="closeBox" size="small" >我知道了</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 查看详情弹框 end -->
  </div>
  
</template>

<script>

import { parseTime } from "@/utils";
import { updateList, updateUserSave, updateListOne} from "@/api/org";
export default {
  name: 'IndexMessage',
  props: {
    tabName: String,
  },
  data() {
    return {
      showBig: false,
      showDownload:false,
      activeName: '1',
      dialogFormVisible:false,
      list:[],
      detail:{},
      downloadList:[],
      srcList:[],
      showViewer:false
    };
  },
  created(){
    this.getList()
  },
  methods: {
    showBig2(e){
      if(e == 1){
        this.activeName = '1'
        this.showDownload = false
        this.showBig = !this.showBig
        updateList({type: 1}).then(res => {
          this.list = res.data.data.list;
        });
      } else if(e == 2){
        this.activeName = '2'
        this.showBig = false
        this.showDownload = !this.showDownload
        updateList({type: 2}).then(res => {
          this.downloadList= res.data.data.list;
        });
      }
    },
    seeDetails(e){
      updateListOne({id:e.id}).then(response => {
        this.detail = response.data.data.info;
        this.dialogFormVisible = true
        this.showBig = false
      });
    },
    getList() {
      updateList({type: 1}).then(response => {
        this.list = response.data.data.list;
        // this.$emit('success',this.list.length)
      });
    },
    closeBox(){
      if(this.detail.id){
        updateUserSave({updateId: this.detail.id}).then(res => {
        });
        this.dialogFormVisible = false
      }
    },
    // 关闭大图预览
    closeViewer() {
      this.showViewer = false
    },
    // 点击图片
    wrapClick(event, item) {
      const target = event.target
      if (target.tagName === 'IMG') {
        console.log(event.target.src)
        event.preventDefault()
        const content = item
        const imgReg = /<img.*?(?:>|\/>)/gi                   // 匹配图片中的img标签
        const srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i            // 匹配图片中的src
        const arr = content.match(imgReg)                   // 筛选出所有的img
        const srcArr = []
        for (let i = 0; i < arr.length; i++) {
          const src = arr[i].match(srcReg)
          // 获取图片地址
          srcArr.push(src[1])
        }
        this.srcList = srcArr
        this.srcList.unshift(event.target.src)
        const set = new Set(this.srcList)
        this.srcList = Array.from(set)
        this.showViewer = true
      }
    },
  }
}
</script>

<style>
.zz{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0);
  z-index: 999;
}
.message_content .el-tabs__item{
  height: 24px !important;
}
.message_content .el-tabs__nav-wrap::after{
  border-bottom: none !important;
}
</style>
<style lang="scss" scoped>

  
.load_list{
  width: 100%;
  margin: 0 auto;
  li{
    border-bottom: 1px solid #eee;
  }
  p{
    font-size: 14px;
    color: #333;
    font-weight: normal;
    position: relative;
    padding-left: 15px;
  }
  p:before{
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $color-main;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }

}
.message_list{
  width: 320px;
  // height: 100%;
  height: calc(100vh - 56px);
  background: #fff;
  border: 1px solid #eee;
  position: fixed;
  right: 0;
  top: 56px;
  z-index: 9999;
  overflow-y: auto;
  padding:40px 12px 12px;
  box-shadow: 0px 8px 6px #ccc;
  .list_card{
    width: 100%;
    li{
      background: #fff;
      box-shadow: 0 2px 10px #ddd;
      border-radius: 8px;
      margin-bottom: 14px;
      border: 1px solid #eee;
      .top_tag{
        width: 100%;
        line-height: 30px;
        background: #f6f6f6;
        padding: 0 10px;
        display: flex;
        flex-direction: row;
        p{
          position: relative;
          padding-left: 15px;
        }
        p::before{
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: $color-main;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
        }
      }
      .message_main{
        h5{
          font-size: 14px;
          color: #333;
          font-weight: normal;
        }
        h6{
          font-size: 14px;
          color: #333;
          font-weight: normal;
        }
        padding:13px 10px;
      }
      .message_p{
        font-size: 14px;
        color: #666;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .bottom_btns{
        width: 100%;
        margin: 0 auto;
        line-height: 30px;
        border-top: 1px solid #eee;
        padding: 0 10px;
        display: flex;
        flex-direction: row;
        justify-content : space-between;
        span{
          color: #666;
          font-size: 14px;
          line-height: 30px;
        }
        p{
          font-size: 14px;
          color: $color-main;
          line-height: 30px;
          cursor: pointer;
        }
      }
    }
  }
  
}
.message_detail{
  height: 420px;
  overflow-y: auto;
  h6{
    width: 96%;
    text-align: left;
    font-weight: normal;
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
  }
  p{
    width: 96%;
    margin: 0 auto;
    font-size: 15px;
    color: #333;
    line-height: 24px;
  }

}
.iconClass{
  font-size: 18px;
  position:absolute;
  top: 14px;
  right: 20px;
  cursor: pointer;
  z-index: 999;
}

</style>

<style lang="scss">
  .contentStyle{
      display:inline-block;
    p{
      img{
        max-width:100%;
        max-height: 100%;
      }
    }
  }
</style>

