<template>
  <el-select @change="changeCode" v-model="id" filterable placeholder="请选择" clearable size="small" :disabled="disabled">
    <el-option
      v-for="item in subjectListOldList"
      :key="item.id"
      :label="item.name3"
      :value="item.id"
    >
      <span style="float: left">{{ item.name3 }}</span>
      <span style="float: right;color: red;font-size: 14px;">{{item.fzhsName}}</span>
    </el-option>
  </el-select>
</template>
<script>
export default {
  props:{
    codes: {//默认下拉框的code
      type: Array,
      default: []
    },
    subjectId: {
      type: Number,
      default: 0
    },
  },
  computed: {
    subjectListOldList(){
      return this.$subjectFilterCode(this.codes, 2)
    },
    id: {
      get() {
        if(!this.subjectId){
          return ""
        }
        return this.subjectId
      },
      set(val) {
        this.$emit("update:subjectId", val * 1)
        this.$emit("success", val * 1)
      }
    }
  },
  methods: {
    
  }
}
</script>