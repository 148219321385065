import mitt from 'mitt'

const emitter = mitt()

export default emitter


// this.$bus.emit('')  
//refreshTask 报税采集任务
// invioceInUpdate 进项发票新增修改
// invioceOutUpdate 销项发票新增修改
// invioceFeeUpdate 费用新增修改
// voucherUpdate 凭证页面
// inventoryManageUpdate 存货管理
// inventoryPeriodUpdate 存货汇总表
// bankUpdate 资金-银行
// cashUpdate 资金-现金
// billUpdate 资金-票据
// inventoryCheckUpdate 存货核算
// fzhsSubjectListUpdate 辅助类别的list
// stopReportingUpdate 停报客户
// addCustomerUpdate 新增客户
// salarySheetUpdate  工资表
// subjectBalanceUpdate 账簿
//coopUpdate crm转到合作客户
//termUpdate  crm转到解约
//contractUpate crm合同更新
//contractUncheck crm合同未审核
//businessTypeUpdate crm业务类型更新
