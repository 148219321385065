import Layout from "@/views/layout/index";

// noCache 不缓存
// hidden 不在左侧菜单栏显示
//show 不受权限影响
const eaRouter = [
  {
    path: "/redirects",
    component: Layout,
    name: "redirects",
    redirect: "/redirects/redirectsindex",
    hidden: true,
    meta: {
      title: "跳转页",
      noCache: true,
      show: true,
    },
    children: [
      {
        path: "redirectsindex",
        component: () => import("@/views/redirect/index"),
        name: "redirectsindex",
        meta: {
          title: "跳转页子叶",
          noCache: true,
          show: true,
        },
      },
    ],
  },
  {
    path: "/",
    name: "home",
    component: Layout,
    redirect: "/homeIndex",
    meta: {
      title: "首页",
      icon: "icon-home",
    },
    // children: [{
    //   path: 'homeIndex',
    //   component: () => import ('@/views/home/homeIndex'),
    //   name: 'homeIndex',
    //   meta: { title: '首页', icon: 'icon-home' },
    // }]
    children: [
      {
        path: "homeIndex",
        component: () => import("@/views/home/homeIndex2"),
        name: "homeIndex",
        meta: { title: "首页", icon: "House" },
      },
    ],
  },
  {
    path: "/company",
    component: Layout,
    name: "company",
    meta: { title: "客户管理", icon: "user" },
    children: [
      {
        path: "addCustomer",
        component: () => import("@/views/company/addCustomer"),
        name: "addCustomer",
        meta: { title: "新增客户", icon: "icon-xinzengkehu1" },
      },
      {
        path: "bookkeeping",
        component: () => import("@/views/company/bookkeeping"),
        name: "bookkeeping",
        meta: { title: "记账客户", icon: "icon-hetongliebiao1" },
      },
      {
        path: "stopReporting",
        component: () => import("@/views/company/stopReporting"),
        name: "stopReporting",
        meta: { title: "停报客户", icon: "icon-tingbaokehu" },
      },
      {
        path: "subject_balance_uploadExcel_new",
        component: () =>
          import("@/views/company/subject_balance_uploadExcel_new"),
        name: "subject_balance_uploadExcel_new",
        meta: { title: "导入科目余额", icon: "xinzengkehu1" },
      },
      {
        path: "editCustomer",
        component: () => import("@/views/company/comInfo"),
        name: "editCustomer",
        meta: { title: "修改客户信息", icon: "jinggao" },
        hidden: true,
      },
      {
        path: "redirection",
        component: () => import("@/views/company/redirection"),
        name: "redirection",
        meta: { title: "跳转修改", icon: "jinggao" },
        hidden: true,
      },
    ],
  },
  {
    path: "/kefu",
    component: Layout,
    name: "kefu",
    meta: { title: "客服管理", icon: "user" },
    children: [
      {
        path: "empower",
        component: () => import("@/views/company/empower"),
        name: "empower",
        meta: { title: "授权页面", icon: "icon-shouquanyemian1" },
      },
      {
        path: "kefuOrgList",
        component: () => import("@/views/company/kefuOrgList"),
        name: "kefuOrgList",
        meta: { title: "公司列表", icon: "icon-gongsiliebiao1" },
      },
    ],
  },
  {
    path: "/initEa",
    component: Layout,
    name: "initEa",
    meta: { title: "初始化操作", icon: "reading" },
    children: [
      {
        path: "kjInit",
        component: () => import("@/views/initOperation/initFinance"),
        name: "kjInit",
        meta: { title: "初始化报表", icon: "icon-chushihuabaobiao1" },
      },
      {
        path: "initChushihua",
        component: () =>
          import("@/views/input/remuneration/components/information"),
        name: "initChushihua",
        meta: { title: "个税初始化", icon: "icon-geshuichushihua1" },
      },
      {
        path: "initVatInvoice",
        component: () => import("@/views/report/tax/vat-invoice/vatInvoice"),
        name: "initVatInvoice",
        meta: { title: "增值税一般纳税人申报表", icon: "icon-home" },
      },
      {
        path: "initSmallVat",
        component: () => import("@/views/report/tax/small-vat/smallVat"),
        name: "initSmallVat",
        meta: { title: "小规模增值税及附加税费申报表", icon: "icon-home" },
      },
      {
        path: "initQysds",
        component: () => import("@/views/report/tax/qysds/qysds"),
        name: "initQysds",
        meta: { title: "企业所得税申报表", icon: "icon-home" },
      },
      {
        path: "initPropertyTax",
        component: () => import("@/views/report/tax/propertyTax/propertyTax"),
        name: "initPropertyTax",
        meta: { title: "财产和行为税纳税申报表", icon: "icon-home" },
      },
      {
        path: "initAdditionalTax",
        component: () => import("@/views/report/tax/otherTable/additionalTax"),
        name: "initAdditionalTax",
        meta: { title: "附加税", icon: "icon-home" },
      },
      {
        path: "initEditTax",
        component: () => import("@/views/report/tax/otherTable/editTax"),
        name: "initEditTax",
        meta: { title: "印花税纳税申报（报告）表", icon: "icon-home" },
      },
      {
        path: "initFcs",
        component: () => import("@/views/report/tax/otherTable/fcs"),
        name: "initFcs",
        meta: { title: "房产税土地税", icon: "icon-home" },
      },
      {
        path: "initTaxNeimengQtsr",
        component: () => import("@/views/report/tax/otherTable/taxNeimengQtsr"),
        name: "initTaxNeimengQtsr",
        meta: { title: " 其他收入(工会经费)", icon: "icon-home" },
      },
      {
        path: "initXfs",
        component: () => import("@/views/report/tax/otherTable/xfs"),
        name: "initXfs",
        meta: { title: "消费税", icon: "icon-home" },
      },
      {
        path: "initTaxNeimengSl",
        component: () => import("@/views/report/tax/otherTable/taxNeimengSl"),
        name: "initTaxNeimengSl",
        meta: { title: "水利建设专项收入", icon: "icon-home" },
      },
      {
        path: "initTaxCityLife",
        component: () => import("@/views/report/tax/otherTable/taxCityLife"),
        name: "initTaxCityLife",
        meta: { title: "城市生活垃圾处置费", icon: "icon-home" },
      },
      {
        path: "initCbj",
        component: () => import("@/views/report/tax/otherTable/cbj"),
        name: "initCbj",
        meta: { title: "残疾人保障金", icon: "icon-home" },
      },
      {
        path: "initCulturalTax",
        component: () => import("@/views/report/tax/otherTable/culturalTax"),
        name: "initCulturalTax",
        meta: { title: "文化事业建设费", icon: "icon-home" },
      },
      {
        path: "kjInitTable",
        component: () => import("@/views/report/finance/finance"),
        name: "kjInitTable",
        meta: { title: "初始化财务报表", icon: "icon-chushihuabaobiao" },
      },
      {
        path: "gsInitTable",
        component: () => import("@/views/report/tax/tax"),
        name: "gsInitTable",
        meta: { title: "初始化税务报表", icon: "icon-caiwubaobiao" },
      },
      {
        path: "oldInit",
        component: () => import("@/views/company/oldInit"),
        name: "oldInit",
        meta: { title: "历史数据", icon: "icon-lishishuju1" },
      },
      {
        path: "addAccounts",
        component: () => import("@/views/company/addAccounts"),
        name: "addAccounts",
        meta: { title: "建账采集", icon: "icon-lishishujucaiji" },
      },
      {
        path: "accountMigrate",
        component: () => import("@/views/company/accountMigrate"),
        name: "accountMigrate",
        meta: { title: "账套迁移", icon: "icon-lishishujucaiji" },
      },
    ],
  },
  {
    path: "/batch",
    component: Layout,
    name: "batch",
    meta: { title: "批量操作", icon: "files" },
    children: [
      {
        path: "batch-send-tax",
        component: () => import("@/views/batch/batch-send-tax"),
        name: "batch-send-tax",
        meta: { title: "电子税局", icon: "icon-dianzishuiju" },
      },
      {
        path: "gsgsSb",
        component: () => import("@/views/batch/gsgsSb"),
        name: "gsgsSb",
        meta: { title: "个税申报", icon: "icon-geshuishenbao2" },
      },
      // {
      //   path: 'sendShebao',
      //   component: () =>
      //       import ('@/views/batch/taxPay'),
      //   name: 'sendShebao',
      //   meta: { title: '社保申报', icon: 'icon-shebaoshenbao1' }
      // },
      {
        path: "taxCheck",
        component: () => import("@/views/batch/taxCheck"),
        name: "taxCheck",
        meta: { title: "批量检查", icon: "icon-piliangjiancha2" },
      },
      {
        path: "collectInvoice",
        component: () => import("@/views/batch/collectInvoice"),
        name: "collectInvoice",
        meta: { title: "票据采集", icon: "icon-piaojucaiji1" },
      },


      {
        path: "taxStatistics",
        component: () => import("@/views/batch/taxStatistics"),
        name: "taxStatistics",
        meta: { title: "税款统计", icon: "icon-shuikuantongji1" },
      },
      {
        path: "yearSb",
        component: () => import("@/views/batch/yearSb"),
        name: "yearSb",
        meta: { title: "汇算清缴", icon: "icon-shuikuantongji1" },
      },
      {
        path: "cwtj",
        component: () => import("@/views/batch/cwtj"),
        name: "cwtj",
        meta: { title: "财务统计", icon: "icon-shuikuantongji1" },
      },
    ],
  },
  {
    path: "/ea-setting",
    component: Layout,
    name: "ea-setting",
    meta: { title: "管理设置", icon: "setting" },
    children: [
      {
        path: 'secure-manage',
        component: () =>
            import ('@/views/ea-setting/secure-manage'),
        name: 'secure-manage',
        meta: { title: '安全设置', icon: 'icon-yanzhengmajilu' }
      },
      {
        path: "sms",
        component: () => import("@/views/ea-setting/sms"),
        name: "sms",
        meta: { title: "验证码记录", icon: "icon-yanzhengmajilu1" },
      },
      {
        path: "taxSetting",
        component: () => import("@/views/ea-setting/org-setting"),
        name: "taxSetting",
        meta: { title: "申报设置", icon: "icon-shenbaoshezhi1" },
      },
    ],
  },
  {
    path: "/user",
    component: Layout,
    name: "user",
    meta: { title: "用户管理", icon: "user" },
    children: [
      {
        path: "user-dep",
        component: () => import("@/views/user/user-dep"),
        name: "user-dep",
        meta: { title: "班级管理", icon: "icon-gongsiliebiao1" },
      },
      {
        path: "user-user",
        component: () => import("@/views/user/user-user"),
        name: "user-user",
        meta: { title: "人员管理", icon: "icon-genjinkehu" },
      },
      {
        path: "user-role",
        component: () => import("@/views/user/user-role"),
        name: "user-role",
        meta: { title: "权限管理", icon: "icon-yonghuguanli1" },
      },
      {
        path: "secure-manage",
        component: () => import("@/views/user/secure-manage"),
        name: "secure-manage",
        meta: { title: "安全设置", icon: "icon-qitashezhi1" },
      },
      {
        path: "group1",
        component: () => import("@/views/user/group1"),
        name: "group1",
        meta: { title: "集团管理", icon: "icon-gongsi" },
      },
      {
        path: "group2",
        component: () => import("@/views/user/group2"),
        name: "group2",
        meta: { title: "账套转移", icon: "icon-chushihuacaozuo" },
      },
    ],
  },
  {
    path: "/pt",
    component: Layout,
    name: "pt",
    meta: { title: "平台管理", icon: "monitor" },
    children: [
      {
        path: "org-manage",
        component: () => import("@/views/platform/orgManage"),
        name: "org-manage",
        meta: { title: "软件用户管理", icon: "icon-yonghuguanli1" },
      },
      {
        path: "setpt",
        component: () => import("@/views/platform/setpt"),
        name: "setpt",
        meta: { title: "系统设置", icon: "icon-xitongshezhi1" },
      },
      {
        path: "update",
        component: () => import("@/views/platform/update"),
        name: "update",
        meta: { title: "更新记录", icon: "icon-gengxinjilu1" },
      },
      {
        path: "trademarkReview",
        component: () => import("@/views/platform/trademarkReview"),
        name: "trademarkReview",
        meta: { title: "商标审核", icon: "icon-shangbiao" },
      },
      {
        path: "courseManagement",
        component: () => import("@/views/platform/courseManagement"),
        name: "courseManagement",
        meta: { title: "课程管理", icon: "icon-kechengguanli" },
      },
    ],
  },

  // 收款系统
  {
    path: "/crm",
    component: Layout,
    name: "crm",
    redirect: "/crmIndex",
    meta: { title: "首页", icon: "icon-home" },
    children: [
      {
        path: "crmIndex",
        component: () => import("@/views/crm/crm/crmIndex"),
        name: "crmIndex",
        meta: { title: "首页", icon: "House" },
      },
    ],
  },
  {
    path: "/custom",
    component: Layout,
    name: "custom",
    meta: { title: "客户管理", icon: "user" },
    children: [
      {
        path: "followCustom",
        component: () => import("@/views/crm/custom/followCustom"),
        name: "followCustom",
        meta: { title: "跟进客户", icon: "icon-genjinkehu1" },
      },
      {
        path: "coopCustom",
        component: () => import("@/views/crm/custom/coopCustom"),
        name: "coopCustom",
        meta: { title: "合作客户", icon: "icon-hezuokehu1" },
      },
      {
        path: "termCustom",
        component: () => import("@/views/crm/custom/termCustom"),
        name: "termCustom",
        meta: { title: "解约客户", icon: "icon-jieyuekehu1" },
      },
    ],
  },

  // 云服务
  {
    path: "/property",
    component: Layout,
    name: "property",
    meta: { title: "知识产权", icon: "document" },
    children: [
      {
        path: "trademark",
        component: () => import("@/views/crm/property/trademark"),
        name: "trademark",
        meta: { title: "商标注册", icon: "icon-shangbiao" },
      },
      {
        path: "propertyOther",
        component: () => import("@/views/crm/property/propertyOther"),
        name: "propertyOther",
        meta: { title: "其他", icon: "icon-qita" },
      },
    ],
  },
  // 学堂
  {
    path: "/course",
    component: Layout,
    name: "course",
    meta: { title: "课程", icon: "document" },
    children: [
      {
        path: "course1",
        component: () => import("@/views/crm/course/course1"),
        name: "course1",
        meta: { title: "初级", icon: "icon-chujihuiji" },
      },
      {
        path: "course2",
        component: () => import("@/views/crm/course/course2"),
        name: "course2",
        meta: { title: "中级", icon: "icon-zhongjihuiji" },
      },
      {
        path: "course3",
        component: () => import("@/views/crm/course/course3"),
        name: "course3",
        meta: { title: "注册会计", icon: "icon-zhucehuiji" },
      },
    ],
  },

  // 题库
  {
    path: "/example",
    component: Layout,
    name: "example",
    meta: { title: "题库", icon: "document" },
    children: [
      {
        path: "exampleTest",
        component: () => import("@/views/example/index"),
        name: "exampleTest",
        meta: { title: "学习题库", icon: "document" },
      },
      // {
      //   path: "exampleTest2",
      //   component: () => import("@/views/example/index"),
      //   name: "exampleTest2",
      //   meta: { title: "题库", icon: "icon-tiku" },
      // },
      // {
      //   path: "exampleTest3",
      //   component: () => import("@/views/example/index"),
      //   name: "exampleTest3",
      //   meta: { title: "题库", icon: "icon-tiku" },
      // },
      {
        path: "example2",
        component: () => import("@/views/example/components/index"),
        name: "example2",
        meta: { title: "在线题库", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "startExam",
        component: () => import("@/views/example/components/startExam"),
        name: "startExam",
        meta: { title: "答题", icon: "icon-home" },
        hidden: true,
      },
    ],
  },
  
  {
    path: "/contract",
    component: Layout,
    name: "contract",
    meta: { title: "合同", icon: "document" },
    children: [
      {
        path: "contractList",
        component: () => import("@/views/crm/contract/contractList"),
        name: "contractList",
        meta: { title: "合同列表", icon: "icon-jizhangkehu2"},
      },
      {
        path: "contractExamine",
        component: () => import("@/views/crm/contract/contractExamine"),
        name: "contractExamine",
        meta: { title: "合同审核", icon: "icon-hetongshenhe1" },
      },
    ],
  },
  {
    path: "/crmSettimg",
    component: Layout,
    name: "crmSettimg",
    meta: { title: "设置", icon: "setting" },
    children: [
      {
        path: "businessType",
        component: () => import("@/views/crm/crmSettimg/businessType"),
        name: "businessType",
        meta: { title: "业务类型", icon: "icon-yewuleixing1" },
      },
      {
        path: "otherSetting",
        component: () => import("@/views/crm/crmSettimg/otherSetting"),
        name: "otherSetting",
        meta: { title: "其他设置", icon: "icon-qitashezhi1" },
      },
    ],
  },

  // 做账内部页面

  {
    path: "/etaxHome",
    name: "etaxHome",
    component: Layout,
    redirect: "/etaxHome/etaxHomeIndex",
    meta: {
      title: "账簿首页",
      icon: "house",
    },
    children: [
      {
        path: "etaxHomeIndex",
        component: () => import("@/views/etaxHome/etaxHomeIndex"),
        name: "etaxHomeIndex",
        meta: { title: "账簿首页", icon: "house" },
      },
    ],
  },
  {
    path: "/input",
    name: "input",
    component: Layout,
    // redirect: '/input/invoice',
    meta: {
      title: "票据录入",
      icon: "edit-pen",
    },
    children: [
      {
        path: "invoice",
        component: () => import("@/views/input/invoice/invoice"),
        name: "invoice",
        meta: { title: "发票", icon: "icon-fapiao1" },
      },
      {
        path: "addIncome",
        component: () => import("@/views/input/invoice/addIncome"),
        name: "addIncome",
        meta: { title: "新增进项", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "incomeWzz",
        component: () => import("@/views/input/invoice/incomeWzz"),
        name: "incomeWzz",
        meta: { title: "未认证进项", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "addOutput",
        component: () => import("@/views/input/invoice/addOutput"),
        name: "addOutput",
        meta: { title: "新增销项", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "money",
        component: () => import("@/views/input/money/money"),
        name: "money",
        meta: { title: "资金", icon: "icon-zijin1" },
      },
      {
        path: "remuneration",
        component: () => import("@/views/input/remuneration/remuneration"),
        name: "remuneration",
        meta: { title: "薪酬", icon: "icon-xinchou1" },
      },
      {
        path: "salaryNormal",
        component: () => import("@/views/input/remuneration/salaryNormal"),
        name: "salaryNormal",
        meta: { title: "正常工资薪资", icon: "icon-home" },
      },
      {
        path: "salaryServices",
        component: () => import("@/views/input/remuneration/salaryServices"),
        name: "salaryServices",
        meta: { title: "劳务报酬所得", icon: "icon-home" },
      },
      {
        path: "salaryBonus",
        component: () => import("@/views/input/remuneration/salaryBonus"),
        name: "salaryBonus",
        meta: { title: "全年一次性奖金", icon: "icon-home" },
      },
      {
        path: "salaryPerson",
        component: () => import("@/views/input/remuneration/salaryPerson"),
        name: "salaryPerson",
        meta: { title: "个人生产经营所得", icon: "icon-home" },
      },
      {
        path: "inventory",
        component: () => import("@/views/input/inventory/inventory"),
        name: "inventory",
        meta: { title: "存货", icon: "icon-cunhuo1" },
      },
      {
        path: "produceConvert",
        component: () => import("@/views/input/inventory/produceConvert"),
        name: "produceConvert",
        meta: { title: "领料加工", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "projectConvert",
        component: () => import("@/views/input/inventory/projectConvert"),
        name: "projectConvert",
        meta: { title: "工程核算", icon: "icon-home" },
        hidden: true,
      },

      {
        path: "fwConvert",
        component: () => import("@/views/input/inventory/fwConvert"),
        name: "fwConvert",
        meta: { title: "服务成本核算", icon: "icon-home" },
        hidden: true,
      },

      {
        path: "processConvert",
        component: () => import("@/views/input/inventory/processConvert"),
        name: "processConvert",
        meta: { title: "委托加工", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "tradeConvert",
        component: () => import("@/views/input/inventory/tradeConvert"),
        name: "tradeConvert",
        meta: { title: "贸易核算", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "assets",
        component: () => import("@/views/input/assets/assets"),
        name: "assets",
        meta: { title: "资产", icon: "icon-zichan1" },
      },
    ],
  },
  {
    path: "/bookkeeps",
    name: "bookkeeps",
    component: Layout,
    // redirect: '/bookkeeps/voucher',
    meta: {
      title: "记账核算",
      icon: "files",
    },
    children: [
      {
        path: "voucher",
        component: () => import("@/views/bookkeeps/voucher"),
        name: "voucher",
        meta: { title: "凭证", icon: "icon-pingzheng1" },
      },
      //历史凭证
      {
        path: "hisVoucher",
        component: () => import("@/views/bookkeeps/hisVoucher"),
        name: "hisVoucher",
        meta: { title: "凭证", icon: "icon-pingzheng1" },
      },
      //历史会计科目
      {
        path: "hisSubject",
        // component: () => import ('@/views/bookkeeps/hisSubject'),
        component: () => import("@/views/setting/subject"),
        name: "hisSubject",
        meta: { title: "会计科目", icon: "icon-kuaijikemu1" },
      },
      {
        path: "addVoucher",
        component: () => import("@/views/bookkeeps/addVoucher"),
        name: "addVoucher",
        meta: { title: "添加凭证", icon: "icon-home" },
      },
      {
        path: "historyVoucher",
        component: () => import("@/views/bookkeeps/components/historyVoucher"),
        name: "historyVoucher",
        meta: { title: "修改凭证", icon: "icon-yewushuju" },
      },
      // {
      //   path: 'calculateTax',
      //   component: () => import ('@/views/bookkeeps/calculateTax'),
      //   name: 'calculate-tax',
      //   meta: { title: '计提税金', icon: 'icon-home' },
      // },
      {
        path: "settleAccount",
        component: () => import("@/views/bookkeeps/newSettleAccount"),
        name: "settle-account",
        meta: { title: "结账", icon: "icon-jiezhang1" },
      },
      {
        path: "accountBook",
        component: () => import("@/views/bookkeeps/accountBook"),
        name: "accountBook",
        meta: { title: "账簿", icon: "icon-zhangbu1" },
      },
      //历史账簿
      // {
      //   path: 'history',
      //   component: () => import ('@/views/bookkeeps/history'),
      //   name: 'history',
      //   meta: { title: '账簿', icon: 'icon-zhangbu' },
      // }
    ],
  },
  {
    path: "/report",
    name: "report",
    component: Layout,
    meta: {
      title: "报表统计",
      icon: "data-analysis",
    },
    children: [
      {
        path: "tax",
        component: () => import("@/views/report/tax/tax"),
        name: "tax",
        meta: { title: "税务报表", icon: "icon-shuiwubaobiao1" },
      },
      {
        path: "finance",
        component: () => import("@/views/report/finance/finance"),
        name: "finance",
        meta: { title: "会计报表", icon: "icon-kuaijibaobiao1" },
      },
      //历史会计报表
      // {
      //   path: 'hisBooks',
      //   component: () => import ('@/views/report/finance/hisBooks'),
      //   name: 'hisBooks',
      //   meta: { title: '会计报表', icon: 'icon-caiwubaobiao' },
      // },
      {
        path: "vatInvoice",
        component: () => import("@/views/report/tax/vat-invoice/vatInvoice"),
        name: "vatInvoice",
        meta: { title: "增值税一般纳税人申报表", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "smallVat",
        component: () => import("@/views/report/tax/small-vat/smallVat"),
        name: "smallVat",
        meta: { title: "小规模增值税及附加税费申报表", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "qysds",
        component: () => import("@/views/report/tax/qysds/qysds"),
        name: "qysds",
        meta: { title: "企业所得税申报表", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "propertyTax",
        component: () => import("@/views/report/tax/propertyTax/propertyTax"),
        name: "propertyTax",
        meta: { title: "财产和行为税纳税申报表", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "additionalTax",
        component: () => import("@/views/report/tax/otherTable/additionalTax"),
        name: "additionalTax",
        meta: { title: "附加税", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "editTax",
        component: () => import("@/views/report/tax/otherTable/editTax"),
        name: "editTax",
        meta: { title: "印花税纳税申报（报告）表", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "fcs",
        component: () => import("@/views/report/tax/otherTable/fcs"),
        name: "fcs",
        meta: { title: "房产税土地税", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "taxNeimengQtsr",
        component: () => import("@/views/report/tax/otherTable/taxNeimengQtsr"),
        name: "taxNeimengQtsr",
        meta: { title: " 其他收入(工会经费)", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "xfs",
        component: () => import("@/views/report/tax/otherTable/xfs"),
        name: "xfs",
        meta: { title: "消费税", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "taxNeimengSl",
        component: () => import("@/views/report/tax/otherTable/taxNeimengSl"),
        name: "taxNeimengSl",
        meta: { title: "水利建设专项收入", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "taxCityLife",
        component: () => import("@/views/report/tax/otherTable/taxCityLife"),
        name: "taxCityLife",
        meta: { title: "城市生活垃圾处置费", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "cbj",
        component: () => import("@/views/report/tax/otherTable/cbj"),
        name: "cbj",
        meta: { title: "残疾人保障金", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "culturalTax",
        component: () => import("@/views/report/tax/otherTable/culturalTax"),
        name: "culturalTax",
        meta: { title: "文化事业建设费", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "normal",
        component: () => import("@/views/report/tax/otherTable/normal"),
        name: "normal",
        meta: { title: "综合所得申报表", icon: "icon-home" },
        hidden: true,
      },

      // 汇算清缴
      // {
      //   path: 'yearTaxList',
      //   component: () => import ('@/views/report/yearTax/yearTaxList'),
      //   name: 'yearTaxList',
      //   meta: { title: '汇算清缴', icon: 'icon-huisuanqingjiao' },
      // },
      {
        path: "A000000",
        component: () => import("@/views/report/yearTax/A000000"),
        name: "A000000",
        meta: { title: "A000000", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A100000",
        component: () => import("@/views/report/yearTax/A100000"),
        name: "A100000",
        meta: { title: "A100000", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A101010",
        component: () => import("@/views/report/yearTax/A101010"),
        name: "A101010",
        meta: { title: "A101010", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A102010",
        component: () => import("@/views/report/yearTax/A102010"),
        name: "A102010",
        meta: { title: "A102010", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A104000",
        component: () => import("@/views/report/yearTax/A104000"),
        name: "A104000",
        meta: { title: "A104000", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A105000",
        component: () => import("@/views/report/yearTax/A105000"),
        name: "A105000",
        meta: { title: "A105000", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A105050",
        component: () => import("@/views/report/yearTax/A105050"),
        name: "A105050",
        meta: { title: "A105050", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A105060",
        component: () => import("@/views/report/yearTax/A105060"),
        name: "A105060",
        meta: { title: "A105060", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A105080",
        component: () => import("@/views/report/yearTax/A105080"),
        name: "A105080",
        meta: { title: "A105080", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A106000",
        component: () => import("@/views/report/yearTax/A106000"),
        name: "A106000",
        meta: { title: "A106000", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A107040",
        component: () => import("@/views/report/yearTax/A107040"),
        name: "A107040",
        meta: { title: "A107040", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A107010",
        component: () => import("@/views/report/yearTax/A107010"),
        name: "A107010",
        meta: { title: "A107010", icon: "icon-home" },
        hidden: true,
      },
    ],
  },
  {
    path: "/setting",
    name: "setting",
    component: Layout,
    meta: {
      title: "设置",
      icon: "setting",
    },
    children: [
      {
        path: "comInfo",
        component: () => import("@/views/company/comInfo"),
        name: "comInfo",
        meta: { title: "企业信息", icon: "icon-qiyexinxi1" },
      },
      {
        path: "subject",
        component: () => import("@/views/setting/subject"),
        name: "subject",
        meta: { title: "会计科目", icon: "icon-kuaijikemu1" },
      },
      {
        path: "fzhs",
        component: () => import("@/views/setting/fzhs"),
        name: "fzhs",
        meta: { title: "辅助核算", icon: "icon-fuzhuhesuan1" },
      },
      {
        path: "business",
        component: () => import("@/views/setting/business"),
        name: "business",
        meta: { title: "业务数据", icon: "icon-yewushuju1" },
      },
      // {
      //   path: 'history',
      //   component: () => import ('@/views/setting/history'),
      //   name: 'history',
      //   meta: { title: '历史数据', icon: 'icon-yewushuju' },
      // },
      // {
      //   path: 'historyVoucher',
      //   component: () => import ('@/views/setting/components/historyVoucher'),
      //   name: 'historyVoucher',
      //   meta: { title: '修改凭证', icon: 'icon-yewushuju' },
      // }
    ],
  },
  {
    path: "/his",
    name: "his",
    component: Layout,
    meta: {
      title: "报表账簿",
      icon: "notebook",
    },
    children: [
      //历史账簿
      {
        path: "history",
        component: () => import("@/views/bookkeeps/history"),
        name: "history",
        meta: { title: "账簿", icon: "icon-zhangbu1" },
      },
      //历史会计报表
      {
        path: "hisBooks",
        component: () => import("@/views/report/finance/hisBooks"),
        name: "hisBooks",
        meta: { title: "会计报表", icon: "icon-kuaijibaobiao1" },
      },
    ],
  },
];

export default eaRouter;
