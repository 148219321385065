import request from '@/utils/request'
// 获取添加凭证时科目信息 
export function getVoucherCodeInfo(data) {
  return request({
      url: 'api/v2/voucher/getVoucherCodeInfo',
      method: 'post',
      data
  })
}

//新增修改凭证
export function saveVoucher(data) {
  return request({
      url: 'api/v2/voucher/saveVoucher',
      method: 'post',
      data
  })
}

//获取最新凭证号
export function getNewVoucherNo(data) {
  return request({
      url: 'api/v2/voucher/getNewVoucherNo',
      method: 'post',
      data
  })
}

//凭证列表
export function voucherList(data) {
  return request({
      url: 'api/v2/voucher/voucherList',
      method: 'post',
      data
  })
}
//凭证详情
export function voucherInfo(data) {
  return request({
      url: 'api/v2/voucher/voucherInfo',
      method: 'post',
      data
  })
}
//删除凭证
export function voucherDel(data) {
  return request({
      url: 'api/v2/voucher/voucherDel',
      method: 'post',
      data
  })
}

//生成凭证
export function voucherPreview(data) {
  return request({
      url: 'api/v2/voucher/voucherPreview',
      method: 'post',
      data
  })
}

//一键生成凭证
export function oneKeyVoucher(data) {
  return request({
      url: 'api/v2/voucher/oneKeyVoucher',
      method: 'post',
      data
  })
}

//凭证合成习惯设置
export function mergeInfo(data) {
  return request({
      url: 'api/v2/voucher/mergeInfo',
      method: 'post',
      data
  })
}

//凭证合成习惯设置
export function mergeSet(data) {
  return request({
      url: 'api/v2/voucher/mergeSet',
      method: 'post',
      data
  })
}

//凭证一键排序
export function voucherSort(data) {
  return request({
      url: 'api/v2/voucher/voucherSort',
      method: 'post',
      data
  })
}

//排序
export function editVoucherNo(data) {
  return request({
      url: 'api/v2/voucher/editVoucherNo',
      method: 'post',
      data
  })
}
//凭证手动合并
export function HandMergeVoucher(data) {
  return request({
      url: 'api/v2/voucher/HandMergeVoucher',
      method: 'post',
      data
  })
}

//凭证模板
export function voucherTemplateList(data) {
  return request({
      url: 'api/v2/voucher/voucherTemplateList',
      method: 'post',
      data
  })
}

//保存为模板，保存
export function voucherTemplateSave(data) {
  return request({
      url: 'api/v2/voucher/voucherTemplateSave',
      method: 'post',
      data
  })
}

//选择自定义模板
export function voucherTemplateInfo(data) {
  return request({
      url: 'api/v2/voucher/voucherTemplateInfo',
      method: 'post',
      data
  })
}

//删除自定义模板
export function voucherTemplateDel(data) {
  return request({
      url: 'api/v2/voucher/voucherTemplateDel',
      method: 'post',
      data
  })
}
//凭证一键审核
export function voucherLook(data) {
  return request({
      url: 'api/v2/voucher/voucherLook',
      method: 'post',
      data
  })
}

//复制
export function saveEaVoucherCopy(data) {
  return request({
      url: 'api/v2/voucher/saveEaVoucherCopy',
      method: 'post',
      data
  })
}

//上张下一张
export function voucherNextInfo(data) {
  return request({
      url: 'api/v2/voucher/voucherNextInfo',
      method: 'post',
      data
  })
}

//拖动排序
export function dragSort(data) {
  return request({
      url: 'api/v2/voucher/dragSort',
      method: 'post',
      data
  })
}

