<template>
  <qzf-button @success="openDialog" size="small" type="primary"><el-icon class="el-icon--right"><Setting /></el-icon>{{name}}</qzf-button>
  <el-dialog :close-on-click-modal="false" destroy-on-close append-to-body :title="name" v-model="dialogTableVisible" right width="80%">
    <el-table :data="list" style="width: 100%;" border :height="contentStyleObj">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column prop="name" label="项目名称" min-width="180" align="center">
      </el-table-column>
      <el-table-column prop="remark" label="设置" min-width="280" align="center">
        <template #default="scope">
          <subject-list v-if="scope.row.valueType == 'select'" :codes="$findCode(scope.row.option)" v-model:subjectId="scope.row.subjectId" v-model:fzhsItemId="scope.row.fzhsId"></subject-list>
          <el-radio-group v-if="scope.row.valueType == 'radio'" v-model="scope.row.value" size="small">
            <el-radio v-for="item in scope.row.option" :label="item.value" :key="item.index">{{item.name}}</el-radio>
          </el-radio-group>
          <el-input v-if="scope.row.valueType == 'input'" size="small" v-model="scope.row.value"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" min-width="280" align="center">
      </el-table-column>
      <el-table-column label="操作" min-width="80" align="center">
        <template #default="scope">
          <el-button @click="saveSetting(scope.row)" type="text" size="small">保存</el-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="batchSave" size="small">批量保存</el-button>
        <!-- <el-button type="primary" @click="dialogTableVisible = false" size="small">确定</el-button> -->
      </span>
    </template>
  </el-dialog>

  <!-- <settings :name="settingName" :type="['code_jjdj','code_jjdj_subject_code']"></settings> -->
</template>


<script>
import { getComSetList , setComSet , setComSets } from "@/api/company";
export default {
  name:'settings',
  props:{
    name:{
      type:String,
      default:'设置'
    },
    type:{
      type:Array,
      default:[]
    }
  },
  data() {
    return {
      list:[],
      dialogTableVisible:false
    }
  },
  created(){
    this.contentStyleObj= this.$getHeight(450)
  },
  methods:{
    getList(){
      let comId = this.$store.getters["commons/params"].editCustomerComId * 1
      if(this.$store.getters["user/comInfo"].comId * 1){
        comId = this.$store.getters["user/comInfo"].comId * 1
      }else if(this.$store.getters["commons/params"].editCustomerComId.id *1){
        comId = this.$store.getters["commons/params"].editCustomerComId.id
      }
      let param = {
        comId:comId,
        types:this.type
      }
      getComSetList(param).then(res=>{
        this.list = res.data.data.list
      })
    },
    // 保存
    saveSetting(row){
      setComSet(row).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          // this.dialogTableVisible = false
        }
      })
    },
    openDialog(){
      this.dialogTableVisible = true
      this.getList()
    },
    batchSave(){
      setComSets(this.list).then(res =>{
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.dialogTableVisible = false
        }else{
          this.$qzfMessage("请查看设置项目",1)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-table .cell .el-radio-group {
  white-space: pre-wrap !important;
  text-align: left;
}
</style>