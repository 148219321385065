<template>
  <el-date-picker @change="changeTime" v-model="period" type="month" value-format="YYYYMM" size="small" placeholder="日期" style="width: 160px;display:inline-block;" :clearable="false"></el-date-picker>
</template>

<script>
export default {
  name:'searchPeriod',
  props:{
    period:''
  },
  methods:{
    changeTime(){
      this.$emit('update:period', this.period)
      this.$emit("success", this.period)
    }
  }
}
</script>

<style>

</style>