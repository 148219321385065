<template>
  <div class="header-big clearfix"> 
    
    <div class="left_header clearfix">
      <!-- <img class="logo" :src="this.logoUrl" alt="" v-if="this.logoUrl"> -->
      <!-- <img class="logo" src="@/assets/logo.png" alt="" v-else> -->
      <img class="logo" src="@/assets/lo22go.png" alt="" />


      <span v-if="!comId" style="display:inline-block;border-left:1px solid #dbdcdd;height: 35px;width: 20px;position: relative;top: 21px;left: 10px;"></span>
      <span v-if="!comId" style="display:inline-block;position:relative;left:0px;top:9px">会计自助实操实训平台</span>
      <span v-if="!comId" style="background-color:#ea5514;border: 1px solid #ea5514;border-radius: 20px;color: #fff;font-size: 1px;padding: 3px;height: 4px;display: inline-block;line-height: 6px;position: relative;left: 10px;">V1 . 0</span>

      <span v-if="comId" style="display:inline-block;width:1px;height:37px;background-color:#efefef;position: absolute;top: 18px;left: 206px;"></span>

      <!-- 公司下拉列表 :disabled="item.disabled" -->
      <span v-if="comId" class="aaaaa" style="margin-top: 21px;display: inline-block;">
        <section v-if="orgId != 1">
          <el-select @change="changeComId" v-model="comId" filterable size="small" placeholder="公司名">
            <el-option
              v-for="item in companys"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
          <el-tooltip effect="dark" content="复制公司名称" placement="top">
            <el-icon @click="copyMessage(comName)" style="cursor: pointer;font-size: 19px;position: relative;top: 3px;"><DocumentCopy /></el-icon>
          </el-tooltip>
          </section>
        <section v-if="orgId == 1">
          {{comName}}
        </section>
      </span>

      <span v-if="comId" class="dateStyle" style="margin-top: 19px;display: inline-block;">
        <i class="el-icon-date" style="position:relative;left:24px;z-index:99"></i>
        <el-icon style="position:relative;left:32px;z-index:99;top: 3px;"><Calendar /></el-icon>
        <qzf-period @success="updatePeriods" v-model:period="period"></qzf-period>
      </span>
      <el-button v-if="comId && this.settleWord =='未结账'" size="small" style="position: relative;bottom: 1px;margin-left: 5px;" type="warning" class="iconfont icon-gantanhao iconfont1">
        <span style="color:#fff">{{settleWord}}</span>
      </el-button>
      <el-button v-if="comId && this.settleWord == '已结账'" size="small" style="position: relative;bottom: 1px;margin-left: 5px;" type="primary" class="iconfont icon-duihao iconfont1">
        <span style="color:#fff">{{settleWord}}</span>
      </el-button>


      <el-popover v-if="comId" v-model:visible="visible" placement="bottom" :width="350">
        <el-input
          v-model="content"
          :rows="4"
          type="textarea"
          placeholder="请输入"
        />
        <div style="text-align: right; margin-top: 10px">
          <el-button size="small" type="primary" @click="visible = false">取消</el-button>
          <el-button size="small" type="primary" @click="editNote">确定</el-button>
        </div>
        <template #reference>
          <span style="border:1px solid #e6e6e6;padding:5px;cursor:pointer;font-size:15px;font-weight:400;padding-top:4px;margin-left: 19px;" @click="smallNotes">
            随手记
            <i class="iconfont icon-jiahao"></i>
          </span>
        </template>
      </el-popover>

      
      <span v-if="comId"  style="margin-left:20px;cursor:pointer" @click="ticketOpen">
        <i class="iconfont icon-bianji"></i>
        <span>{{filterStatus(this.piaoStatus)}}</span>
      </span>
      <!-- todo -->

      <ticket-status ref="ticketStatus" :period="period" :comId="comId" @success="getPiaoStatus"></ticket-status>

      <div class="nav1" v-if="!comId" style="display:none">
        <span :class="{cur:item.value == thisNav}" v-for="(item, index) in nav" :key="index" @click="goEtax(item.value, false)">
          <i class="iconfont icon-xinzengkehu" v-if="item.value == '初始建账'"></i>
          <i class="iconfont icon-jizhangkehu" v-if="item.value == '记账核算'"></i>
          <i class="iconfont icon-dianzishuiju" v-if="item.value == '批量申报'"></i>
          {{item.name}}</span>
      </div>
    </div>

    
    <div class="right_header clearfix">

      <!-- <el-dropdown>
        <span class="el-dropdown-link">
          <div class="header-message">
            <i class="el-icon-user"></i>
            <span>{{userName}}</span>
          </div>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>使用说明</el-dropdown-item>
            <el-dropdown-item>系统设置</el-dropdown-item>
            <el-dropdown-item>
              <div @click="logout()" class="header-goback">
                <i class="iconfont icon-tuichu"></i>
                退出
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown> -->

      <div v-if="!comName" class="header-message">
        <el-icon><OfficeBuilding /></el-icon>
        <span>{{orgName}}</span>
      </div>
  
      <!-- <div v-if="comName" class="header-name" style="margin-top:3px">
        <i class="iconfont icon-qiyexinxi"></i>
        <span>{{comName}}</span>
      </div> -->
      <div class="header-message" @click="messageShow(1)">
          <el-badge :value="messageTotal" class="item" :hidden="!messageTotal">
            <el-icon><Bell /></el-icon>
            <span>通知</span>
          </el-badge>
      </div>

      <!-- <div class="header-message" @click="goSms">
        <el-icon><Message /></el-icon>
        <span>验证码记录</span> 
      </div> -->
      <!-- <div class="header-message common_icon" @click="messageShow(2)">
        <i class="iconfont icon-xiazai"></i>
        <span id="downloadFont">下载</span>
      </div> -->
      <div @click="goback()" v-if="comId" class="header-message">
        <el-icon><Right /></el-icon>
        <span>返回</span> 
      </div>
      <div style="display:inline-block" class="header-message" @click="guanlishezhi" v-if="settingShow">
        <el-icon><Setting /></el-icon>
        <span>管理设置</span> 
      </div>


      <!-- <div v-if="!comName" class="header-message" @click="messageShow(1)">
        <i class="el-icon-user"></i>
        <span>{{userName}}</span>
      </div> -->


      <div class="header-goback">
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <el-icon><User /></el-icon>
            <span style="position:relative;top:-1px;margin-left:3px">{{userName}}</span>
            <el-icon><CaretBottom /></el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu :style="{marginLeft:left + 'px'}">
              <el-dropdown-item>
                <span @click="messageShow(2)"> 
                <i class="iconfont icon-xiazai"></i>
                  下 载</span>
              </el-dropdown-item>
              <el-dropdown-item @click="logout()">
                <span >
                  <el-icon><SwitchButton /></el-icon>
                  <span style="margin-left: 7px;position: relative;top: -1px;">退 出</span>
                </span>
              </el-dropdown-item>
              <!-- <el-dropdown-item v-for="(item, index) in users" :key="index" @click="goUser(item.id)">
                <span >
                  {{ item.cnName }}
                </span>
              </el-dropdown-item> -->
            </el-dropdown-menu>
            <el-select v-model="changeUserId" filterable style="width: 130px;margin-bottom:10px;" clearable @change="changeUser" placeholder="选择切换账号" v-if="users.length != 0">
              <el-option
                v-for="item in users"
                :key="item.id"
                :label="item.cnName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </el-dropdown>
        <!-- <el-select v-model="changeUserId" filterable style="width: 280px;" clearable @change="changeUser">
                  <el-option
                    v-for="item in users"
                    :key="item.id"
                    :label="item.cnName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select> -->
      </div>


      <!-- <div @click="logout()" class="header-goback">
        <i class="iconfont icon-tuichu"></i>
        退出
      </div> -->
    </div>
    <message ref="message" @success="getMessageTotal"></message>
  </div>
</template>
<script>
import { updateRead } from '@/api/org.js'
import { findPeople } from '@/api/base.js'

import { companyList, userSaveComId } from "@/api/ea";
import { updatePeriod ,updateUser} from "@/api/login";
import store from "@/store";
import { eaCompanyList ,comMemoryInfo ,comMemorySave ,getDpStatus } from "@/api/company"
import message from "./information.vue";
// import uploadImg from "@/components/uploadImg/upload";

export default {
  name: "header",
  components: {
    message,
    
  },
  data () {
    return {
      comId: this.$store.getters["user/comInfo"].comId * 1,
      // users: [],
      users: this.$store.getters["user/users"],

      // period: ,//"202201",//
      companyId: {},
      companys: [],
      companysSearch: [],
      filterText: "",
      // comName:this.$store.getters["user/comInfo"].comName,
      userName:this.$store.getters['user/user'].cnName,
      orgName:this.$store.getters['user/user'].orgName,
      orgId:this.$store.getters['user/user'].orgId,
      settingShow:false,
      visible:false,
      content:'',
      dialogVisible:false,
      piaoStatus:2,
      piaoItems:[],
      settleWord:'',
      messageTotal:0,
      changeUserId:undefined,
      logoUrl:this.$store.getters["user/setOrgInfo"].logoUrl
    }
  },
  computed: {
    thisNav(){
      return store.getters['user/model']
    },
    period(){
      return store.getters["user/comInfo"].period
    },
    comName(){
      return store.getters["user/comInfo"].comName
    },
    nav(){
      let nav = []
      let nav2 = []
      if(this.$store.getters["user/user"].primaryRouting){
        nav = this.$store.getters["user/user"].primaryRouting.map(v=>{
          if(v == '管理设置'){
            this.settingShow = true
            return
          }
          nav2.push({
            name:v,
            value:v
          })
        })
      }
      return nav2
    },
  },
  created(){
    this.init()
  },
  mounted() {
    updateRead({}).then(response => {
      if(response.data.data.status){
        this.messageTotal = 1
        this.$refs.message.detail = response.data.data.info;
        this.$refs.message.dialogFormVisible = true;
      }
    });
    let param = {
      comId:this.comId,
      period:this.$store.getters["user/comInfo"].period
    }
    this.$refs.ticketStatus.ticketStatus(param)
  },
  methods: {
    init(){
      eaCompanyList({status: "2",userIds:[this.$store.getters['user/user'].userId]}).then(res=>{
        this.companys = res.data.data.list
      })
      if(this.$store.getters['commons/periods'].length !== 0 && this.$store.getters['commons/periods'][0].options[0].settleStatus == '4'){
        this.settleWord = '已结账'
      }else{
        this.settleWord = '未结账'
      }
      if(this.users.length != 0){
        this.left = 20
      }else{
        this.left = 0
      }
      // findPeople({deptId:0,code:"bookkeeping",eduCode:"",}).then(res => {
      //   this.users = res.data.data.list
      // })
    },
    changeUser(){
      this.goUser(this.changeUserId)
    },
    goUser(id){
      updateUser({id:id}).then(res=>{
        location.reload()
      })
    },
    getMessageTotal(val){
      this.messageTotal =val
    },  
    getPiaoStatus(e){
      this.piaoStatus = e
    },
    filterStatus(val){
      if(val == 9){
        return '未到票'
      }else if(val == 2){
        return '全部到票'
      }else if(val == 1){
        return '部分到票'
      }
    },
    goback(){
      // opener: 谁打开我的, 注意它不是parent!!
      if (window.opener) { // 主页面还存在
        // 关闭当前页
        // 切换到主页面 how ?
        // window.open(window.opener.location.href,  '_parent'); // 可以, 因为当前页面没有parent
        window.close()
      } else { // 主页面已经关了
        // location.reload() 
        window.open(location.origin,  '_blank');
        window.close();
      }
    },
    changeComId(){
      this.$store.dispatch("user/setComInfo", {
        comId: this.comId,
      })
      window.name = this.comId + "##accountBook"
      this.goEtax("正常", true)
    },
    goEtax(e, force){
      //为了防止重复调用 userInfo 做个判断
      if(store.getters["user/model"] == e && !force){
        this.gogogo(e)
        return
      }
      this.$store.dispatch("user/setModel", e)
      this.$store.dispatch('user/GetUserInfo').then(res=>{
        // getRouter(res.roles)
        this.$store.dispatch('router/SetAsyncRouter',res.roles).then(res=>{
          this.$store.dispatch('tagsView/delAllViews')
          this.gogogo(e)
        })
      }).catch(err=>{
        //有问题 返回登录页面 直接清空token 就可
        // removeToken()
        // location.reload()
      })
    },
    gogogo(e){
      if(e == "正常"){
        this.$router.replace({
            path: '/redirects',
            query: {
              path: '/etaxHome/etaxHomeIndex',
            },
        })
      }else{
        // 学生账号落到安全设置里
        if(this.$store.getters['router/asyncRouters'][1].children[0].name != 'user-dep'){
          this.$router.replace({
            path: '/redirects',
            query: {
              path: '/user/secure-manage',
            },
          })
          return
        }
        this.$router.replace({
          path: '/redirects',
          query: {
            path: this.$store.getters["user/indexPath"],
          },
        })
      }
    },
    //更改账期
    updatePeriods(data){
      if(data.old == true){
        this.settleWord = '已结账'
      }else{
        this.$store.getters['commons/periods'][0].options.map(v => {
          if(data.period == v.period){
            if(v.settleStatus == '4'){
              this.settleWord = '已结账'
            }else{
              this.settleWord = '未结账'
            }
          }
        })
      }

      let comInfo = store.getters["user/comInfo"]
      comInfo.period = data.period
      updatePeriod({period: data.period}).then(res=>{
        if(res.data.msg == "success"){
          comInfo.comType = res.data.data.info.comType
          comInfo.comKj = res.data.data.info.comKj
          comInfo.jzsy = res.data.data.info.jzsy
          this.$store.dispatch("user/setComInfo", comInfo)
          //刷新
          this.$store.dispatch("tagsView/delAllViews"); //关闭
          // this.$router.push('/'); //lcs账期
          if(data.old){
            this.goEtax("历史", false)
          }else{
            this.goEtax("正常", false)
          }
        }
      })
    },
    //消息
    messageShow(e){
      this.tabName = e
      this.$refs.message.showBig2(this.tabName)
    },
    logout(){
      this.$store.dispatch('user/LogOut').then(() => {
        if(window.name.match("accountBook")){
          window.open(location.origin,  '_blank');
          window.close();
        }else{
          location.reload()
        }
      })
    },
    guanlishezhi(){
      this.goEtax('管理设置',false)
    },
    smallNotes(){
      this.visible = true
      comMemoryInfo({}).then(res=>{
        this.content = res.data.data.content
      })
    },
    editNote(){
      comMemorySave({content:this.content}).then(res=>{
        if(res.data.msg == "success"){
          this.visible = false
          this.$qzfMessage("保存成功")
        }
      })
    },
    ticketOpen(){
      this.$refs.ticketStatus.openDialog()
      let params = {
        comId: this.$store.getters["user/comInfo"].comId * 1,
        period: this.$store.getters["user/comInfo"].period
      }
      this.$refs.ticketStatus.ticketStatus(params)
    },
    goSms(){
      this.$router.push({
        path: "/ea-setting/sms",  
      });
    },
    copyMessage(value){
      console.log(value);
			const newObj =  value
			//创建input标签
      var input = document.createElement('input')
      //将input的值设置为需要复制的内容
      input.value = newObj;
      //添加input标签
      document.body.appendChild(input)
      //选中input标签
      input.select()
      //执行复制
      document.execCommand('copy')
      //成功提示信息
      this.$message.success('复制成功！')
      //移除input标签
      document.body.removeChild(input) 
		},
  },
  
}
</script>

<style lang="scss" scoped>
.company-span .el-select .el-input__inner{
  border:0 !important;
  padding-right: 30px;
}
.company-span .el-input__inner{
  padding: 0 6px !important;
}
.company-span .el-input__inner{
  position: relative;
  top: -3px;
}
.company-span .el-input__suffix {
  top: -2px;
}
.header-big {
  .el-dropdown-selfdefine{
    color: $color-header-color;
  }
}
.user-header{
  width: 120px;
  .user-menu{
    color: $color-header-color !important;
    display: inline-block;
    width: 100%;
    height: 35px;
    font-size: 14px;
    font-weight: 300;
    // padding-left: 10px;
    line-height: 30px;
    border-bottom: 1px solid #2c3445;
    cursor: pointer;
    &:last-child{
      border-bottom: none;
    }
  }
  border: none;
}


</style>
<style lang="scss" scoped>
.iconfont1 {
  color: white;
}
.header-big{
  position: relative;
  width: 100%;
  height: 70px;
  padding: 0 15px 0 0px;
  border-bottom: 3px solid #eee;
  background: #fff;
  // color: $color-main;
  box-shadow: 0 2px 10px #ddd;
  
  .logo{
    // position: absolute;
    // top: 3px;
    // left: 27px;
    margin-top: 0px;
    float: left;
    width: 189px;
    height: 62px;
    font-size: 14px;
    margin-left: 7px;
  }
  .left_header{
    float: left;
    // margin-top: 10px;
  }
  .right_header{
    float: right;
    padding-top: 18px;
    span{
      font-size: 16px;
      font-weight: 400;
    }
    
  }
  .header-name{
    float: left;
    i{
      vertical-align: top;
      color: $color-header-color;
      line-height: 25px;
    }
    span{
      // vertical-align: top;
      font-size: 15px;
      margin-left: 5px;
      color: $color-header-color;
    }
    margin-right: 10px;
  }
  .header-message {
    float: left;
    font-size: 15px;
    cursor: pointer;
    line-height: 29px;
    color: theme;
    color: $color-header-color;
    padding-right: 30px;
    margin-top: 2px;
    i {
      font-size: 16px;
    }
    span{
      margin-left:5px;
      position: relative;
      top: -2px;
    }
  }
  .header-goback{
    float: left;
    font-size: 17px;
    cursor: pointer;
    line-height: 34px;
    color: $color-header-color;
    position: relative;
    top: 5px;
  }
  .header-fanhui{
    float: left;
    font-size: 15px;
    cursor: pointer;
    line-height: 32px;
    color: $color-header-color;
    width: 80px;
    height: 35px;
    // text-align: center;
    // border: 1px solid white;
    border-radius: 6px;
    // margin-right: 10px;
  }
}
.company-span{
  height: 30px;
  i{
    line-height: 30px;
    color: #666;
  }
}
.company{
  min-width: 300px;
  height: 30px;
}
.el-icon-printer{
  vertical-align: sub;
}
.nav1{
  display: inline-block;
  padding: 0px;
  margin-left: 3px;
  span{
    color: $color-header-color;
    font-size: 16px;
    display: inline-block;
    width: 135px;
    font-weight: 500;
    line-height: 52px;
    position: relative;
    text-align: center;
    box-sizing:border-box;
    cursor: pointer;
    // }
    // &::before {
    //   width: 1px;
    //   background: #c2c2c2;
    //   content: " ";
    //   clear: both;
    //   height: 30px;
    //   position: absolute;
    //   top: 10px;
    //   left: 0;
    // }
    // &:last-child{
    //   &::after {
    //     width: 1px;
    //     background: #c2c2c2;
    //     content: " ";
    //     clear: both;
    //     height: 30px;
    //     position: absolute;
    //     top: 10px;
    //     right: 0;
    //   }
    // }
  }
}
.cur{
  border-bottom: 3px solid $color-main;
}
.menuStyle{
  text-align: center;
}

</style>

<style lang="less" scoped>
.el-scrollbar .el-dropdown-menu{
  padding: 0px;
  background: #fff;
  border-radius: 0px;
}
.el-scrollbar .el-dropdown-menu{
  padding: 0px;
  background: #fff;
  border-radius: 0px;
}
// .el-scrollbar .el-dropdown-menu .el-dropdown-menu__item :hover{
//   color: white;
//   border-bottom: 1px solid white;
// }
</style>
<style lang="scss">
.aaaaa{
  margin-left: 18px;
.el-input__inner{
    background-color: #fff;
    width:285px;
    border:1px solid #e6e6e6 !important;
    font-size: 15px;
    font-weight: 500;
  }
}
.dateStyle{
.el-input__inner{
    background-color: #f1f1f1;
    border:1px solid #e6e6e6 !important;
    font-size: 15px;
    width: 143px;
    font-weight: 500;
    padding-left: 28px;
    padding-right: 0px;
  }
}
</style>