<template>
  <div class="left-big">
    <el-menu
      ref="menus"
      default-active="2"
      class="el-menu-vertical-demo"
      :default-openeds="opens"
      @open="handleOpen"
      @close="handleClose"
    >
      <!-- 仅支持2级菜单 路由必须写2级的 -->
      <template v-for="(item,index) in routers">
        <!-- style="background: $color-main;" -->
        <el-sub-menu style="background: $color-main !important;color: #fff !important;" v-if="item.children && item.children.length > 1 && !item.hidden" :index="index">
          <template #title>

            <el-icon style="position:relative;bottom:2px;font-size: 17px;margin-right: 6px;">
              <component :is="item.meta.icon" />
            </el-icon>
            <span>&nbsp;</span>
            <span style="color: #212529 !important;">{{item.meta.title}}</span>
          </template>
          
          <!-- <router-link v-for="(itemC,indexC) in item.children" class="router-link" :to="`${item.path}/${itemC.path}`"> -->
          <router-link v-for="(itemC,indexC) in item.children" class="router-link" :to="{name: itemC.name}">
            <el-menu-item v-if="!itemC.hidden" :index="`${index}-${indexC}`">
              <el-icon style="position:relative;bottom:1px;font-size: 17px;margin-left: 10px;">
                <!-- {{itemC.meta.icon}} -->
                <i :class="'iconfont'+' '+itemC.meta.icon" style="font-size: 17px;"></i>
                <!-- <component :is="itemC.meta.icon" /> -->
              </el-icon>
              <span>&nbsp;&nbsp;</span>
              <span>{{itemC.meta.title}}</span>
            </el-menu-item>
          </router-link>
        </el-sub-menu>

        <router-link v-else-if="item.children && item.children.length == 1 && !item.hidden" class="router-link" :to="{name: item.children[0].name}">
          <el-menu-item :index="index">
            <el-icon style="position:relative;bottom:2px;font-size: 16px;margin-right: 6px;">
              <component :is="item.children[0].meta.icon" />
            </el-icon>
            <span>&nbsp;&nbsp;</span>
            <span>{{item.children[0].meta.title}}</span>
          </el-menu-item>
        </router-link>

        <router-link v-else-if="!item.hidden" class="router-link" :to="item.path">
          <el-menu-item :index="index">
            <span>{{item.meta.title}}</span>
          </el-menu-item>
        </router-link>


      </template>
    </el-menu>
  </div>
</template>

<script>
import store from '@/store'
export default {
  name: 'left',
  data () {
    return {
      // opens: ["/index", "/setting"], // 设置菜单栏展开, 默认展开子菜单 
      opens: [0, 1, 2, 3, 4, 5], // 设置菜单栏展开, 默认展开子菜单 
      routers: store.getters['router/asyncRouters'],
    }
  },
  computed: {
    routers(){
      return store.getters['router/asyncRouters']
      
    },
  },
  created () {
    // console.log(this.routers)
    this.routers.map(v=>{
      // console.log(v)
    })
  },
  methods: {
    handleClose(key, keyPath) {
      this.$refs.menus.open(keyPath);
    },
  }
}
</script>
<style lang="scss" scoped>
.iconfont{
  font-size: 16px;
  margin-right: 10px;
}
.left-big{
  // overflow-y: auto;
  height: 100%;
  // background: #ebf1f7;
  background: #fff;
  span{
    font-size: 15px;
  }
}

/*滚动条宽度*/
      .left-big::-webkit-scrollbar {
        width: 4px;
      }
      /*滚动条里面小方块*/
      .left-big::-webkit-scrollbar-thumb {
		  border-radius: 5px;
		  // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		  background: rgba(100, 100, 100, 0.2);
		}
		/*滚动条里面轨道*/
		// .left-big::-webkit-scrollbar-track {
		//   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		//   border-radius: 0;
		//   background: rgba(0, 0, 0, 0.1);
		// }

</style>
<style lang="scss">
.router-link{
 text-decoration:none;
}
.el-sub-menu__title:hover{
   background-color: #17a2b8 !important; 
}

</style>
<style lang="scss">
.el-sub-menu__title{
  background-color: #dcedff !important;
  line-height:42px !important;
  height: 42px !important;
  font-weight: 500;
  font-size: 16px;
  padding-left: 13px !important;
}
.el-menu-item{
  line-height:42px !important;
  height: 40px !important;
  font-weight: 500;
  font-size: 16px;
}
</style>
<style lang="scss" scoped>
.el-sub-menu .el-menu-item{
  padding-left: 20px !important;
  padding-top: 5px;
  min-width: 20px;
}
</style>