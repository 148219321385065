import request from '@/utils/request'

export function loginByUsername(username, password,captchaId,captchaValue,captchaType,pointJson,token) {
  const data = {
    username,
    password,
    captchaId,
    captchaValue,
    captchaType,
    pointJson,
    token
  }
  return request({
    url: '/api/public/login',
    method: 'post',
    data
  })
}

//新增客户企业信息(激活建议)
export function collectionCorp(data) {
  return request({
    url: '/api/public/collectionCorp',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/api/v2/logout',
    method: 'get'
  })
}

export function getUserInfo(data) {
  return request({
    url: '/api/v2/userInfo',
    method: 'post',
    data
  })
}

export function updatePeriod(data) {
  return request({
    url: '/api/v2/updatePeriod',
    method: 'post',
    data
  })
}

export function sendSms(data) {
  return request({
    url: '/api/v2/sendSms',
    method: 'post',
    data
  })
}

export function findPass(data) {
  return request({
    url: '/api/v2/findPass',
    method: 'post',
    data
  })
}
export function registerRemind(data) {
  return request({
    url: '/api/v2/registerRemind',
    method: 'post',
    data
  })
}

export function updateUser(data) {
  return request({
    url: '/api/v2/updateUser',
    method: 'post',
    data
  })
}