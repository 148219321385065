<template>
  <div class="tabZ">
    <div class="leftTab">
      <!-- left -->
      <div class="tabList">历史明细账</div>
    </div>
    <div class="rightTab">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    
  }
}
</script>

<style lang="scss" scoped>
  .tabZ {
    display: flex;
    width: 100%;
    .leftTab {
      // flex: 0.5;
      width: 3%;
      .tabList {
        width: 40px;
        margin: 0 auto;
        // height: 60px;
        line-height: 20px;
        writing-mode: vertical-lr;/*从左向右 从右向左是 writing-mode: vertical-rl;*/
        // writing-mode: tb-lr;/*IE浏览器的从左向右 从右向左是 writing-mode: tb-rl；*/
        border: 0px;
        background-color: #efefef;
        padding: 16px 8px;
      }
    }
    .rightTab {
      // flex: 30;
      width: 95%;
      // height: 100%;
    }
  }
</style>