import request from '@/utils/request'

//选择区域
export function getBaseDistrict(data) {
	return request({
		url: 'api/public/getBaseDistrict',
		method: 'post',
		data
	})
}



//
export function getCaptcha(data) {
	return request({
		url: 'api/public/getCaptcha',
		method: 'post',
		data
	})
}