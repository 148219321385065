<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="开票单位信息维护" v-model="dialogVisible" width="30%">
    <el-form ref="form" :model="form" label-width="110px" class="form_style" size="small" label-position="right" :rules="rules">
      <el-form-item label="纳税人名称：" style="width:90%" >
       <el-input v-model="form.name" disabled></el-input>
      </el-form-item>
      <el-form-item label="纳税人识别号：" style="width:90%">
       <el-input v-model="form.tax_no" disabled></el-input>
       </el-form-item>
      <el-form-item label="税控盘编号：" style="width:90%">
        <el-input v-model="form.tax_number" disabled></el-input>
      </el-form-item>
      <el-form-item label="销方地址：" style="width:90%" prop="address">
        <el-input v-model="form.address"></el-input>
      </el-form-item>
      <el-form-item label="销方电话：" style="width:90%" prop="tel">
        <el-input v-model="form.tel"></el-input>
      </el-form-item>
      <el-form-item label="开户行名称：" style="width:90%" prop="khhmc">
        <el-input v-model="form.khhmc"></el-input>
      </el-form-item>
      <el-form-item label="开户行账号：" style="width:90%" prop="khhzh">
        <el-input v-model="form.khhzh"></el-input>
      </el-form-item>
      <el-form-item label="收款人：" style="width:90%" prop="skr">
        <el-input v-model="form.skr"></el-input>
      </el-form-item>
      <el-form-item label="复核人" style="width:90%" prop="fkr">
        <el-input v-model="form.fkr"></el-input>
      </el-form-item>
      <el-form-item label="开票人" style="width:90%" prop="kpr">
        <el-input v-model="form.kpr"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="this.dialogVisible = false" size="small">关 闭</el-button>
        <el-button @click="resetForm('form')" size="small">重 置</el-button>
        <el-button type="primary" @click="addCorp('form')" size="small">保 存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref, onBeforeUnmount } from 'vue'
import { addCorp } from "@/api/ea";
export default {
  name: 'addSeller',
  data(){
    return {
      dialogVisible: false,
      form: {
        name: this.$store.getters['user/comInfo'].name,
        tax_no: this.$store.getters['user/comInfo'].tax_no,
        tax_number: this.$store.getters['user/comInfo'].jqbh,
        address: '',
        tel: '',
        khhmc: '',
        khhzh: '',
        skr: '',
        fkr:'',
        kpr:'',
        comId:this.$store.getters['user/comInfo'].id
      },
      tableData: [],
      rules: {
        address: [
          { required: true, message: '请输入地址', trigger: 'blur' },
        ],
        tel: [
          { required: true, message: '请输入电话', trigger: 'blur' },
        ],
        khhmc: [
          { required: true, message: '请输入开户行名称', trigger: 'blur' },
        ],
        khhzh: [
          { required: true, message: '请输入开户行账号',  trigger: 'blur' },
        ],
        skr: [
          { required: true, message: '请输入收款人',  trigger: 'blur' },
        ],
        fkr: [
          { required: true, message: '请输入复核人', trigger: 'blur' },
        ],
        kpr: [
          { required: true, message: '请输入开票人',trigger: 'blur' },
        ],
      }
    }
  },
  methods:{
    addCorp(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addCorp([this.form]).then(res=>{ 
            if(res.data.msg == 'success'){
              if(!this.form.id){
                this.$qzfMessage("新增成功", 3)
              }else{
                this.$qzfMessage("修改成功", 3)
              }
              this.dialogVisible = false
              this.$emit("success", res.data.data.info)
            }else{
              this.$qzfMessage("新增失败", 2)
            }
          })
        } else {
          return false
        }
      }) 
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
  }
}
</script>

<style lang="scss" scoped>
.header{
  text-align: right;
  padding: 10px 10px 10px 10px;
}
 
</style>