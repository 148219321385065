import request from '@/utils/request'

// 建账第一步
export function findPeople(data) {
  return request({
    url: 'api/v2/base/findPeople',
    method: 'post',
    data
  })
}

export function businessType(data) {
  return request({
    url: 'api/v2/base/businessType',
    method: 'post',
    data
  })
}

// 查询班级列表
export function getClassByTeacher(data) {
  return request({
    url: 'api/v2/base/getClassByTeacher',
    method: 'post',
    data
  })
}
